import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import './Terms.css';

const Terms = () => {
    return (
        <div>
            <Header />
            <section id="terms" className="terms-section">
                <div className="container">
                    <div className="terms-content">
                        <h2 className="section-title">Terms & Conditions</h2>
                        <div className="terms-info">
                            <p>Welcome to ShootConnect! By using our platform and mobile app, you agree to the following terms and conditions:</p>

                            <h3>1. Introduction</h3>
                            <p>ShootConnect provides a platform for clay pigeon shooting enthusiasts to connect, track scores, and find clubs. By accessing or using our platform, you agree to comply with and be bound by these terms and conditions.</p>

                            <h3>2. User Accounts</h3>
                            <p>To use certain features of the platform, you must create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

                            <h3>3. Privacy Policy</h3>
                            <p>Your privacy is important to us. Our Privacy Policy explains how we collect, use, and protect your personal information. By using our platform, you consent to our collection and use of your information as outlined in the Privacy Policy.</p>

                            <h3>4. Use of the Platform</h3>
                            <p>You agree to use the platform for lawful purposes only. You must not use the platform in any way that causes, or may cause, damage to the platform or impairment of the availability or accessibility of the platform.</p>

                            <h3>5. Intellectual Property</h3>
                            <p>All content on the platform, including text, graphics, logos, and images, is the property of ShootConnect or its content suppliers and is protected by copyright and other intellectual property laws.</p>
                        </div>
                    </div>
                    <div className="privacy-content">
                        <h2 className="section-title">Privacy Policy</h2>
                        <div className="privacy-info">
                            <p>This Privacy Policy describes how ShootConnect collects, uses, and protects your personal information when you use our platform and mobile app.</p>

                            <h3>1. Information We Collect</h3>
                            <p>We may collect personal information such as your name, email address, location, and profile information when you create an account or use our platform.</p>

                            <h3>2. How We Use Your Information</h3>
                            <p>We use your information to provide and improve our services, communicate with you, and personalize your experience on the platform.</p>

                            <h3>3. Information Sharing</h3>
                            <p>We do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights.</p>

                            <h3>4. Data Security</h3>
                            <p>We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure.</p>
                        </div>
                    </div>
                    <div className="contact-info">
                        <h3>Contact Us</h3>
                        <p>If you have any questions or concerns about our Terms & Conditions or Privacy Policy, please contact us at support@shootconnect.com.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Terms;