import React, { useState, useEffect, useCallback } from 'react';
import { X } from 'lucide-react';
import './Equipment.css';
import BrandAutocomplete from './BrandAutocomplete';

const EquipmentModal = ({
    categories,
    onClose,
    onSubmit,
    onCategoryChange,
    specifications,
    equipment,
    isEdit
}) => {
    const [selectedCategory, setSelectedCategory] = useState(null);


    // Initialize basic form data
    const [formData, setFormData] = useState({
        categoryId: equipment?.categoryId || '',
        brandId: equipment?.brandId || '',
        model: equipment?.model || '',
        description: equipment?.description || '',
        purchaseDate: equipment?.purchaseDate?.split('T')[0] || '',
        purchasePrice: equipment?.purchasePrice || '',
        condition: equipment?.condition || 'New',
        serialNumber: equipment?.serialNumber || '',
        specifications: []
    });

    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    // Effect to handle initial specifications setup and updates
    useEffect(() => {
        if (equipment?.specifications && specifications?.length > 0) {
            setFormData(prev => ({
                ...prev,
                specifications: equipment.specifications.map(spec => {
                    const matchingDefinition = specifications.find(def => def.name === spec.name);
                    return {
                        definitionId: matchingDefinition?.definitionId || spec.specificationId,
                        value: spec.value
                    };
                })
            }));
        }
    }, [equipment?.specifications, specifications]);

    // Effect to handle category changes
    useEffect(() => {
        if (formData.categoryId) {
            const category = categories.find(c => c.categoryId === parseInt(formData.categoryId));
            setSelectedCategory(category);
            onCategoryChange(formData.categoryId);
        }
    }, [formData.categoryId, categories, onCategoryChange]);

    const validateField = useCallback((name, value) => {
        switch (name) {
            case 'categoryId':
                return !value ? 'Category is required' : '';
            case 'brandId':
                return !value ? 'Brand is required' : '';
            case 'purchasePrice':
                return value && isNaN(value) ? 'Must be a valid number' : '';
            default:
                return '';
        }
    }, []);

    const handleChange = useCallback((e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
            ...(name === 'categoryId' ? { specifications: [], brandId: '' } : {})
        }));

        const error = validateField(name, type === 'checkbox' ? checked : value);
        setErrors(prev => ({ ...prev, [name]: error }));
        setTouched(prev => ({ ...prev, [name]: true }));
    }, [validateField]);

    const handleSpecificationChange = useCallback((definitionId, value) => {
        setFormData(prev => ({
            ...prev,
            specifications: [
                ...prev.specifications.filter(s => Number(s.definitionId) !== Number(definitionId)),
                { definitionId: Number(definitionId), value }
            ]
        }));
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        const newErrors = {};
        ['categoryId', 'brandId'].forEach(key => {
            const error = validateField(key, formData[key]);
            if (error) newErrors[key] = error;
        });

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            const dataToSubmit = {
                ...formData,
                purchaseDate: formData.purchaseDate || null,
                purchasePrice: formData.purchasePrice || null
            };
            onSubmit(dataToSubmit);
        }
    }, [formData, validateField, onSubmit]);

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>{isEdit ? 'Edit Equipment' : 'Add Equipment'}</h2>
                    <button className="close-btn" onClick={onClose}>
                        <X size={20} />
                    </button>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="categoryId" className='dashboard-form-label'>
                                    Category
                                    <span>*</span>
                                </label>
                                <div className="dashboard-form-field">
                                    <select
                                        id="categoryId"
                                        name="categoryId"
                                        value={formData.categoryId}
                                        onChange={handleChange}
                                        className={touched.categoryId && errors.categoryId ? 'error' : ''}
                                    >
                                        <option value="">Select Category</option>
                                        {categories.map(cat => (
                                            <option key={cat.categoryId} value={cat.categoryId}>
                                                {cat.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {errors.categoryId && touched.categoryId && (
                                    <span className="error-message">{errors.categoryId}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="brandId" className='dashboard-form-label'>
                                    Brand
                                    <span>*</span>
                                </label>
                                <div className="dashboard-form-field">
                                    <BrandAutocomplete
                                        value={formData.brandId}
                                        onChange={handleChange}
                                        categoryId={formData.categoryId}
                                        error={errors.brandId}
                                        touched={touched.brandId}
                                    />
                                </div>
                            </div>
                        </div>
                        {selectedCategory?.showModel && (
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="model" className='dashboard-form-label'>
                                        Model
                                        <span>*</span>
                                    </label>
                                    <div className="dashboard-form-field">
                                        <input
                                            type="text"
                                            id="model"
                                            name="model"
                                            value={formData.model}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {selectedCategory?.showSerialNumber && (
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="serialNumber" className='dashboard-form-label'>
                                        Serial Number
                                        <span>*</span>
                                    </label>
                                    <div className="dashboard-form-field">
                                        <input
                                            type="text"
                                            id="serialNumber"
                                            name="serialNumber"
                                            value={formData.serialNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {specifications?.length > 0 && (
                        <>
                            <h5 className='my-3'>Specifications</h5>
                            <div className="row">
                                {specifications.map(spec => {
                                    const matchingSpec = formData.specifications.find(s =>
                                        Number(s.definitionId) === Number(spec.definitionId)
                                    );

                                    return (
                                        <div className="col-lg-6">
                                            <div key={spec.definitionId} className="form-group">
                                                <label className='dashboard-form-label' htmlFor={`spec-${spec.definitionId}`}>
                                                    {spec.name}
                                                    {spec.isRequired && <span>*</span>}
                                                </label>
                                                {spec.type === 'Select' ? (
                                                    <div className="dashboard-form-field">
                                                        <select
                                                            id={`spec-${spec.definitionId}`}
                                                            value={matchingSpec?.value || ''}
                                                            onChange={(e) => handleSpecificationChange(spec.definitionId, e.target.value)}
                                                        >
                                                            <option value="">Select {spec.name}</option>
                                                            {JSON.parse(spec.options || '[]').map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div className="input-with-unit">
                                                        <div className="dashboard-form-field" style={{ width: 'fit-content', flex: 1 }}>
                                                            <input
                                                                type={spec.type === 'Number' ? 'number' : 'text'}
                                                                id={`spec-${spec.definitionId}`}
                                                                value={matchingSpec?.value || ''}
                                                                onChange={(e) => handleSpecificationChange(spec.definitionId, e.target.value)}
                                                                step={spec.type === 'Number' ? 'any' : undefined}
                                                            />
                                                        </div>
                                                        {spec.unit && <span className="unit">{spec.unit}</span>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    <h5 className='mt-3 mb-1'>Optional Details</h5>
                    <p className="help-text">
                        {selectedCategory?.pricingHelpText ||
                            "This information helps us share average pricing with other shooters"}
                    </p>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className='dashboard-form-label' htmlFor="purchaseDate">Purchase Date</label>
                                <div className="dashboard-form-field">
                                    <input
                                        type="date"
                                        id="purchaseDate"
                                        name="purchaseDate"
                                        value={formData.purchaseDate}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className='dashboard-form-label' htmlFor="purchasePrice">Purchase Price</label>
                                <div className="dashboard-form-field">
                                    <input
                                        type="number"
                                        id="purchasePrice"
                                        name="purchasePrice"
                                        value={formData.purchasePrice}
                                        onChange={handleChange}
                                        step="0.01"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className='dashboard-form-label' htmlFor="condition">Condition</label>
                                <div className="dashboard-form-field">
                                    <select
                                        id="condition"
                                        name="condition"
                                        value={formData.condition}
                                        onChange={handleChange}
                                    >
                                        <option value="New">New</option>
                                        <option value="Excellent">Excellent</option>
                                        <option value="Good">Good</option>
                                        <option value="Fair">Fair</option>
                                        <option value="Poor">Poor</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea
                                    className='dashboard-form-textarea'
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="modal-ctas">
                        <button type="button" className="dashboard-outline-btn me-2" onClick={onClose}>
                            Cancel
                        </button>
                        <button type="submit" className="dashboard-default-btn">
                            {isEdit ? 'Update Equipment' : 'Add Equipment'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default React.memo(EquipmentModal);