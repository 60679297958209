import React from 'react';
import avatar from '../../../assets/images/profile.png'
import { Calendar, Crosshair, Mail, Phone, Target } from 'lucide-react';
import './ProfileView.css'

const ProfileView = () => {
    

    return (
        <div className='dashboard-component'>
            <div className="dashboard-heading">
                <h1>Profile</h1>
                <h2>Update your profile and settings to ensure you and your friends always get up to date news & notifications</h2>
            </div>
            <div className="row">
                <div className="col-lg-3 py-3">
                    <div className="profile-preview">
                        <img 
                            src={avatar} 
                            alt="Profile" 
                            className='profile-preview-img' 
                        />
                        <h5 className="profile-preview-name">John Doe</h5>
                        <h6 className='profile-preview-username'>@john_doe</h6>
                        <div className="profile-preview-line"></div>
                        <div className="dashboard-card-cta m-auto" style={{ width: '270px', maxWidth: '100%' }}>
                            <button
                                className="dashboard-card-outline-btn"
                            >
                                Decline
                            </button>
                            <button
                                className="dashboard-card-default-btn"
                            >
                                Accept
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 py-3">
                    <h4 className="dashboard-component-title">General Information</h4>
                    <div className="profile-info-item">
                        <h6><Mail size={18} className='me-2' /> Email:</h6>
                        <span>johndoe@gmail.com</span>
                    </div>
                    <div className="profile-info-item">
                        <h6><Phone size={18} className='me-2' /> Phone number:</h6>
                        <span>+1234567890</span>
                    </div>
                    <div className="profile-info-item">
                        <h6><Calendar size={18} className='me-2' /> Date of birth:</h6>
                        <span>11-04-1990</span>
                    </div>
                    <h4 className="dashboard-component-title mt-4">Performance</h4>
                    <div className="profile-info-item">
                        <h6><Target size={18} className='me-2' /> Last shot with:</h6>
                        <span>Winchester model 70</span>
                    </div>
                    <div className="profile-info-item">
                        <h6><Calendar size={18} className='me-2' /> Last event date:</h6>
                        <span>11-04-2024</span>
                    </div>
                    <div className="profile-info-item">
                        <h6><Crosshair size={18} className='me-2' /> Accuracy percentage:</h6>
                        <span>85%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileView;