import React from 'react'
import './DashboardFooter.css'


const DashboardFooter = () => {
  return (
    <footer className='dashboard-footer'>
        
        <h6 className="dashboard-footer-right">
            &copy;2024 ShootConnect. All right reserved.
        </h6>
    </footer>
  )
}

export default DashboardFooter