import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Search, ChevronDown } from 'lucide-react';
import { ApiRepos } from '../../../../contracts/ContractVars';

const ShooterSelector = ({ selectedShooter, onShooterSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [availableShooters, setAvailableShooters] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [guestName, setGuestName] = useState('');
    const dropdownRef = useRef(null);

    const fetchAvailableShooters = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}events/available-shooters`, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch available shooters');
            const data = await response.json();
            setAvailableShooters(data);
            setSearchResults(data);
        } catch (error) {
            console.error('Error fetching available shooters:', error);
        }
    }, []);

    useEffect(() => {
        fetchAvailableShooters();

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [fetchAvailableShooters]);

    const handleSearch = useCallback(() => {
        if (!searchTerm.trim()) {
            setSearchResults(availableShooters);
            return;
        }

        const results = availableShooters.filter(shooter =>
            `${shooter.firstName || ''} ${shooter.lastName || ''} ${shooter.shooterName || ''}`
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        );
        setSearchResults(results);
    }, [searchTerm, availableShooters]);

    useEffect(() => {
        handleSearch();
    }, [searchTerm, handleSearch]);

    const handleGuestSubmit = (e) => {
        e.preventDefault();
        if (!guestName.trim()) return;

        onShooterSelect({
            appUserId: null,
            shooterName: guestName.trim()
        });
        setGuestName('');
        setIsOpen(false);
    };

    const getName = (shooter) => {
        if (shooter.shooterName) return shooter.shooterName;
        if (shooter.firstName || shooter.lastName) return `${shooter.firstName || ''} ${shooter.lastName || ''}`.trim();
        return 'Unknown Shooter';
    };

    return (
        <div className="dropdown create-event-dropdown" ref={dropdownRef}>
            <button
                type="button"
                className="club-selector-button"
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
            >
                <span className="shooter-selector-text">
                    {selectedShooter ? getName(selectedShooter) : 'Add a shooter'}
                </span>
                <ChevronDown className={`w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
            </button>

            {isOpen && (
                <div className="shooter-selector-dropdown">
                    <div>
                        <h5 className='mb-3 mt-4'>Choose from friends</h5>
                        {availableShooters.length > 5 && (
                            <div className="flex items-center gap-2 p-2 bg-gray-50 rounded mb-2">
                                <Search className="w-4 h-4" />
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search friends..."
                                    className="w-full bg-transparent border-none outline-none"
                                />
                            </div>
                        )}

                        <div className="max-h-40 overflow-y-auto mb-4">
                            <ul className='dashboard-form-dropdown-list'>
                                {searchResults.map((shooter) => (
                                    <li
                                        key={shooter.appUserId || `guest-${shooter.shooterName}`}
                                        onClick={() => {
                                            onShooterSelect(shooter);
                                            setIsOpen(false);
                                        }}
                                    >
                                        {getName(shooter)}
                                    </li>
                                ))}
                            </ul>
                            {searchResults.length === 0 && (
                                <p className="text-gray-500 text-sm py-2 text-center">No friends found</p>
                            )}
                        </div>

                        <div className="border-t">
                            <h5 className='my-3'>Add a guest shooter</h5>
                            <form onSubmit={handleGuestSubmit}>
                                <div className="dashboard-search-container">
                                    <div className="dashboard-search-field" style={{ flex: 1 }}>
                                        <input
                                            type="text"
                                            placeholder="Enter guest name"
                                            value={guestName}
                                            onChange={(e) => setGuestName(e.target.value)}
                                        />
                                    </div>
                                    <button 
                                        className='dashboard-search-submit'
                                        type='submit'
                                        disabled={!guestName.trim()}
                                    >Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShooterSelector;