import React, { useState } from 'react';
import './Header.css';
import logo from '../../assets/images/logo.svg';
import user from '../../assets/icons/user.svg';
import { Link } from 'react-router-dom';

const Header = ({ openLoginModal, isAuthenticated }) => {
    const [menu, setMenu] = useState(false);

    return (
        <header className="header">
            <nav className="container header-navbar">
                <Link to={'/'} className='header-logo'>
                    <img src={logo} alt="logo" />
                </Link>
                <div className={`header-right ${menu && 'active'}`}>
                    <ul className='header-navlinks'>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/clubs">Clubs</Link>
                        </li>
                        {!isAuthenticated && (
                            <li>
                                <Link to="/register">Register</Link>
                            </li>
                        )}
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                    </ul>
                    <div className="header-ctas">
                        {isAuthenticated ? (
                            <Link to="/dashboard" className='header-dashboard-link'>
                                <img src={user} alt="dashboard" />
                                Dashboard
                            </Link>
                        ) : (
                            <button onClick={openLoginModal} className='header-login-link'>
                                <img src={user} alt="login" />
                                Login
                            </button>
                        )}
                    </div>
                </div>
                <div
                    id="menu-icon"
                    className={menu === true ? 'open' : ''}
                    onClick={() => setMenu(!menu)}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </nav>
        </header>
    );
};

export default Header;