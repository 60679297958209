import React, { useState } from 'react';
import './EventCard.css';
import { Calendar, Crosshair, FilePenLine, MapPin, Trash2, UserRoundPen } from 'lucide-react';

const EventCard = ({ event, onEdit, onEditShooters, onDelete, onManageScoreSheets }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const formattedDate = new Date(event.eventDate).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const handleDelete = async (e) => {
        e.preventDefault();
        if (isDeleting) return;

        setIsDeleting(true);
        try {
            await onDelete();
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div className="dashboard-card">
            <div className="event-card-header">
                <h3 className="dashboard-card-headline">{event.name}</h3>
                <div className="event-card-header-actions">
                    <button 
                        className='square-action blue-action'
                        onClick={onEdit}
                    >
                        <FilePenLine size={15} />
                    </button>
                    <button 
                        className={`square-action red-action ${isDeleting ? 'disabled' : ''}`}
                        disabled={isDeleting}
                        onClick={handleDelete}
                    >
                        <Trash2 size={15} />
                    </button>
                </div>
            </div>
            <div className="event-card-info">
                <h6><Calendar size={18} /> <span>{formattedDate}</span></h6>
                <h6><MapPin size={18} /> <span>{event.clubName}</span></h6>
            </div>
            <div className="dashboard-card-cta mt-5">
                <button
                    className="dashboard-card-outline-btn"
                    onClick={onEditShooters}
                >
                    <UserRoundPen size={15} className='me-2' />
                    Edit Shooters
                </button>
                <button
                    className="dashboard-card-default-btn"
                    onClick={onManageScoreSheets}
                >
                    <Crosshair size={15} className='me-2' />
                    Score Shoot
                </button>
            </div>
        </div>
    );
};

export default EventCard;
