import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown } from 'lucide-react';
import { ApiRepos } from '../../../../contracts/ContractVars';
import './ClubSelector.css';

const ClubSelector = ({ selectedClub, onClubSelect }) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [favoriteClubs, setFavoriteClubs] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchFavoriteClubs();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fetchFavoriteClubs = async () => {
        try {
            const response = await fetch(`${ApiRepos}clubs/favorite`, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch favorite clubs');
            const data = await response.json();
            setFavoriteClubs(data);
        } catch (error) {
            console.error('Error fetching favorite clubs:', error);
        }
    };

    const handleSearch = async () => {
        if (!searchTerm.trim()) return;
        setIsLoading(true);
        try {
            const response = await fetch(`${ApiRepos}clubs/search?searchTerm=${encodeURIComponent(searchTerm)}`, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to search clubs');
            const data = await response.json();
            setSearchResults(data);
        } catch (error) {
            console.error('Error searching clubs:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Handle search when Enter key is pressed
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch();
        }
    };

    const handleClubSelection = (club) => {
        onClubSelect(club);
        setIsOpen(false);
        setSearchTerm('');
        setSearchResults([]);
    };

    const selectedClubName = selectedClub?.name ||
        favoriteClubs.find(club => club.clubId === selectedClub?.id)?.name ||
        'Select Club';

    return (
        <div ref={dropdownRef}>
            <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="club-selector-button"
            >
                <span className={`club-selector-text ${selectedClub ? '' : 'text-[#67605E]'}`}>
                    {selectedClubName}
                </span>
                <ChevronDown className={`w-5 h-5 text-[#67605E] transition-transform ${isOpen ? 'rotate-180' : ''}`} />
            </button>

            {isOpen && (
                <div className="club-selector-dropdown p-2">
                    <div className="dashboard-search-container">
                        <div className="dashboard-search-field" style={{ flex: 1 }}>
                            <input 
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder="Search clubs..."
                                className='border-0'
                            />
                        </div>
                        <button 
                            className='dashboard-search-submit'
                            onClick={handleSearch}
                            disabled={isLoading}
                        >{isLoading ? 'Searching...' : 'Search'}</button>
                    </div>

                    <div className="max-h-64 overflow-y-auto">
                        {favoriteClubs.length > 0 && (
                            <>
                                <h6 className='mb-2'>Favorite Clubs</h6>
                                <ul className='dashboard-form-dropdown-list'>
                                    {favoriteClubs.map((club) => (
                                        <li
                                            key={club.clubId}
                                            onClick={() => handleClubSelection(club)}
                                        >
                                            {club.name}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}

                        {searchResults.length > 0 && (
                            <>
                                <h6 className='mb-2'>Search Clubs</h6>
                                <ul className='dashboard-form-dropdown-list'>
                                    {searchResults.map((club) => (
                                        <li
                                            key={club.clubId}
                                            onClick={() => handleClubSelection(club)}
                                        >
                                            {club.name}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}

                        {searchTerm && !isLoading && searchResults.length === 0 && (
                            <div className="px-5 py-4 text-sm text-[#67605E] text-center">
                                No clubs found
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClubSelector;
