import React, { useState, useEffect } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useUser } from '../../../UserContext';
import './Subscription.css';

const fetchSubscriptionStatus = async (setSubscriptionStatus, setError) => {
    try {
        const response = await fetch(`${ApiRepos}subscription/status`, {
            method: 'GET',
            credentials: 'include'
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to fetch subscription status');
        }
        const data = await response.json();
        setSubscriptionStatus(data.status);
    } catch (error) {
        console.error('Error fetching subscription status:', error);
        setSubscriptionStatus('Error');
        setError('Failed to fetch subscription status. Please try again later.');
    }
};

export const isVIP = (subscriptionStatus) => {
    return subscriptionStatus === 'VIP/Premium';
};

export const VIPCheck = ({ children, fallback }) => {
    const { user } = useUser();
    return isVIP(user.subscriptionStatus) ? children : fallback;
};

const Subscription = () => {
    const { user, setUser } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState(user.subscriptionStatus || 'Free');
    const [error, setError] = useState(null);

    useEffect(() => {
        // Only update subscription status, not the whole user state
        fetchSubscriptionStatus(setSubscriptionStatus, setError);
    }, [setUser]);

    const handleUpgrade = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${ApiRepos}subscription/create-checkout-session`, {
                method: 'POST',
                credentials: 'include'
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create checkout session');
            }
            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
            } else {
                throw new Error('No checkout URL received from the server');
            }
        } catch (error) {
            console.error('Error upgrading subscription:', error);
            setError(error.message || 'Failed to upgrade subscription. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const poundSign = '\u00A3';

    const subscriptionTiers = [
        {
            name: 'Free',
            price: `${poundSign}0`,
            features: [
                'Basic user profile',
                'Activity recording',
                'Basic performance tracking',
                'Club search (5 favorites)',
                'Event participation',
                'Limited event creation (2/month)',
                'Basic scoring (10 participants)',
                'Social features (20 friends)',
                'Basic leaderboards',
                'Simple challenges'
            ]
        },
        {
            name: 'VIP/Premium',
            price: `${poundSign}7.99/month`,
            features: [
                'Enhanced user profile',
                'Advanced performance analysis',
                'Unlimited club features',
                'Unlimited event creation',
                'Advanced scoring features',
                'Comprehensive social features',
                'Advanced leaderboards',
                'Custom challenges',
                'Training plans and analysis',
                'Personal heatmaps',
                'Goal setting and tracking',
                'Advanced analytics',
                'Data export and integration',
                'Priority support',
                'Ad-free experience'
            ]
        },
    ];

    return (
        <div className='events-container'>
            <div className="events-header">
                <h1>Subscription</h1>
                <p className="subtitle">
                   VIP Subscription gets you access to unrestricted Shoot Connect Tools, offers & experiences!
                </p>
            </div>
            {error && <div className="error-message">{error}</div>}
            <h3>Current Subscription: <span className="status">{subscriptionStatus}</span></h3>
            {!isVIP(subscriptionStatus) && (
                <button onClick={handleUpgrade} disabled={isLoading} className="upgrade-button">
                    {isLoading ? 'Processing...' : 'Upgrade to VIP/Premium'}
                </button>
            )}
            <div className="subscription-tiers">
                <h3>Subscription Tiers</h3>
                <div className="tier-container">
                    {subscriptionTiers.map((tier, index) => (
                        <div key={index} className={`tier ${tier.name === subscriptionStatus ? 'current-tier' : ''}`}>
                            <h4>{tier.name}</h4>
                            <p className="price">{tier.price}</p>
                            <ul>
                                {tier.features.map((feature, featureIndex) => (
                                    <li key={featureIndex}>{feature}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Subscription;