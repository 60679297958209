import React, { useState, useEffect } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import './Equipment.css';

const BrandAutocomplete = ({ value, onChange, categoryId, error, touched }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [initialLoadDone, setInitialLoadDone] = useState(false);

    // Initialize searchQuery with existing brand name if in edit mode
    useEffect(() => {
        const initializeBrandName = async () => {
            if (value && !initialLoadDone) {
                try {
                    const response = await fetch(`${ApiRepos}brand/${value}`, {
                        credentials: 'include'
                    });
                    if (response.ok) {
                        const brand = await response.json();
                        setSearchQuery(brand.name);
                    }
                } catch (err) {
                    console.error('Error fetching brand name:', err);
                } finally {
                    setInitialLoadDone(true);
                }
            } else if (!value) {
                setSearchQuery('');
            }
        };

        initializeBrandName();
    }, [value, initialLoadDone]);

    useEffect(() => {
        const fetchSuggestions = async () => {
            // Only fetch if we have a category
            if (!categoryId) {
                setSuggestions([]);
                return;
            }

            try {
                // Construct query string with required categoryId
                const queryString = new URLSearchParams({
                    categoryId: categoryId,
                    ...(searchQuery ? { query: searchQuery } : {})
                }).toString();

                const response = await fetch(
                    `${ApiRepos}brand/search?${queryString}`,
                    { credentials: 'include' }
                );

                if (response.ok) {
                    const data = await response.json();
                    setSuggestions(data);
                }
            } catch (err) {
                console.error('Error fetching brand suggestions:', err);
            }
        };

        // Fetch immediately when category changes
        fetchSuggestions();

        // Also set up debounced fetch for search input changes
        const timeoutId = setTimeout(fetchSuggestions, 300);
        return () => clearTimeout(timeoutId);
    }, [searchQuery, categoryId]);

    const handleSelect = (brand) => {
        setSearchQuery(brand.name);
        onChange({ target: { name: 'brandId', value: brand.brandId } });
        setShowSuggestions(false);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        setShowSuggestions(true);

        if (!value) {
            onChange({ target: { name: 'brandId', value: '' } });
        }
    };

    const handleInputBlur = () => {
        // Hide suggestions after a short delay to allow click events to register
        setTimeout(() => {
            if (!value) {
                setSearchQuery('');
            }
            setShowSuggestions(false);
        }, 200);
    };

    const handleInputFocus = () => {
        setShowSuggestions(true);
    };

    return (
        <div className="brand-autocomplete">
            <input
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Search or select brand..."
                className={touched && error ? 'error' : ''}
                disabled={!categoryId}
            />
            {showSuggestions && suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((brand) => (
                        <li
                            key={brand.brandId}
                            onClick={() => handleSelect(brand)}
                            onMouseDown={(e) => e.preventDefault()} // Prevent input blur from hiding suggestions
                        >
                            {brand.name}
                        </li>
                    ))}
                </ul>
            )}
            {touched && error && (
                <span className="error-message">{error}</span>
            )}
        </div>
    );
};

export default React.memo(BrandAutocomplete);