import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Home.css'
import { ApiRepos } from '../../contracts/ContractVars';

import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import LoginModal from '../../components/loginSection/LoginModal';
import HeroSection from './components/HeroSection'
import FeaturesSection from './components/FeaturesSection'

const Home = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.message) {
            setMessage(location.state.message);
            window.history.replaceState({}, document.title);
        }

        const checkAuth = async () => {
            try {
                const response = await fetch(`${ApiRepos}account/verify`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                if (!response.ok) {
                    throw new Error(data.error || 'Authentication check failed.');
                }

                setIsAuthenticated(data.isAuthenticated);
            } catch (err) {
                console.error('Authentication check error:', err);
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, [location]);

    const openLoginModal = () => setIsLoginModalOpen(true);
    const closeLoginModal = () => setIsLoginModalOpen(false);

    return (
        <div>
            <Header
                openLoginModal={openLoginModal}
                isAuthenticated={isAuthenticated}
            />
            {message && (
                <div className="alert alert-info" style={{
                    padding: '10px',
                    backgroundColor: '#d4edda',
                    color: '#155724',
                    marginBottom: '15px',
                    borderRadius: '4px',
                    textAlign: 'center'
                }}>
                    {message}
                </div>
            )}
            <HeroSection />
            <FeaturesSection />
            {isAuthenticated === true && (
                <div className="go-to-dashboard">
                    <Link to="/dashboard" className="dashboard-button">Go to Dashboard</Link>
                </div>
            )}
            <Footer />
            <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
        </div>
    );
};

export default Home;