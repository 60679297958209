import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Register.css';
import middleCircle from '../../assets/images/login-middle-circle.svg';
import bottomCircle from '../../assets/images/login-bottom-circle.svg';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { ApiRepos } from '../../contracts/ContractVars';
import LoginModal from '../../components/loginSection/LoginModal';

const RegisterSection = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        countryCode: '+44',
        mobileNumber: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        dobDay: '',        
        dobMonth: '',     
        dobYear: '',      
        invitationToken: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [registrationComplete, setRegistrationComplete] = useState(false);
    const [registeredMobileNumber, setRegisteredMobileNumber] = useState('');

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        if (token) {
            setFormData(prevState => ({ ...prevState, invitationToken: token }));
        }
    }, [location]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const openLoginModal = () => {
        setIsLoginModalOpen(true);
    };

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    };

    const formatMobileNumber = (countryCode, number) => {
        const cleanNumber = number.replace(/\D/g, '');
        const numberWithoutLeadingZero = cleanNumber.replace(/^0+/, '');
        return `${countryCode}${numberWithoutLeadingZero}`;
    };

    const isValidMobileNumber = (number) => {
        // Match backend validation patterns
        const ukPattern = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;  // UK format
        const usPattern = /^(\+1\s?(\d{3}|\(\d{3}\))[-.\s]?)?\d{3}[-.\s]?\d{4}$/;  // US format
        const euPattern = /^\+?(\d{1,4}[-.\s]?)?\(?\d{2,4}\)?([-.\s]?\d{3,4}){2,3}$/;  // EU format

        return ukPattern.test(number) || usPattern.test(number) || euPattern.test(number);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prevent resubmission if already at OTP stage
        if (step === 3) {
            return;
        }

        setIsLoading(true);
        setError('');
        setSuccess('');
        
        if (!isValidEmail(formData.email)) {
            setError("Please enter a valid email address.");
            setIsLoading(false);
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setError("Passwords don't match");
            setIsLoading(false);
            return;
        }

        const fullMobileNumber = formatMobileNumber(formData.countryCode, formData.mobileNumber);
        if (!isValidMobileNumber(fullMobileNumber)) {
            setError('Please enter a valid mobile number. This is required for OTP verification.');
            setIsLoading(false);
            return;
        }

        const dateOfBirth = formData.dobYear && formData.dobMonth && formData.dobDay
            ? `${formData.dobYear}-${formData.dobMonth.padStart(2, '0')}-${formData.dobDay.padStart(2, '0')}`
            : null;

        const registrationData = {
            username: formData.username,
            email: formData.email,
            password: formData.password,
            mobileNumber: fullMobileNumber,
            firstName: formData.firstName,
            lastName: formData.lastName,
            dateOfBirth: dateOfBirth  // Use the formatted date of birth here
        };

        // Only include the invitationToken if it exists
        if (formData.invitationToken) {
            registrationData.invitationToken = formData.invitationToken;
        }

        try {
            const response = await fetch(`${ApiRepos}account/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(registrationData)
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Registration initiated:', data);
                setSuccess('Registration initiated. Please check your phone for an OTP.');
                setRegisteredMobileNumber(fullMobileNumber); // Store the mobile number for OTP verification
                setStep(3);
            } else {
                setError(data.error || "Registration failed.");
            }
        } catch (err) {
            setError("An unexpected error occurred. Please try again.");
            console.error("Error during registration:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await fetch(`${ApiRepos}account/verify-otp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    mobileNumber: registeredMobileNumber, // Use stored mobile number
                    otp: otp
                })
            });

            const data = await response.json();

            if (response.ok) {
                setSuccess('Registration successful! You can now log in.');
                setStep(4);
                setRegistrationComplete(true);
            } else {
                setError(data.error || "OTP verification failed.");
            }
        } catch (err) {
            setError("An unexpected error occurred. Please try again.");
            console.error("Error during OTP verification:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLoginAfterRegistration = () => {
        setIsLoginModalOpen(true);
    };

    const renderDateOfBirthDropdowns = () => {
        const days = Array.from({ length: 31 }, (_, i) => i + 1);
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

        return (
            <div className="form-field">
                <label className="form-label">Date of Birth</label>
                <div className="row">
                    <div className="col-md-4">
                        <select
                            name="dobDay"
                            className="text-field"
                            value={formData.dobDay}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Day</option>
                            {days.map(day => (
                                <option key={day} value={day}>{day}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select
                            name="dobMonth"
                            className="text-field"
                            value={formData.dobMonth}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Month</option>
                            {months.map((month, index) => (
                                <option key={month} value={index + 1}>{month}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select
                            name="dobYear"
                            className="text-field"
                            value={formData.dobYear}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Year</option>
                            {years.map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        );
    };

    const renderStep1 = () => (
        <>
            <div className="form-field">
                <label htmlFor="username" className="form-label">
                    Username <span>*</span>
                </label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    className="text-field"
                    value={formData.username}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-field">
                <label htmlFor="email" className="form-label">
                    Email <span>*</span>
                </label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="text-field"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-field">
                <label htmlFor="password" className="form-label">
                    Password <span>*</span>
                </label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    className="text-field"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-field">
                <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password <span>*</span>
                </label>
                <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    className="text-field"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-submit">
                <button className="default-btn" type="button" onClick={() => setStep(2)} disabled={isLoading}>
                    Next
                </button>
            </div>
        </>
    );

    const renderStep2 = () => (
        <>
            <div className="row">
                <div className="col-md-3">
                    <div className="form-field">
                        <label htmlFor="countryCode" className="form-label">
                            Country Code <span>*</span>
                        </label>
                        <select
                            id="countryCode"
                            name="countryCode"
                            className="text-field"
                            value={formData.countryCode}
                            onChange={handleChange}
                            required
                        >
                            <option value="+44">+44 (UK)</option>
                            <option value="+1">+1 (US/Canada)</option>
                            <option value="+33">+33 (France)</option>
                            <option value="+49">+49 (Germany)</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="form-field">
                        <label htmlFor="mobileNumber" className="form-label">
                            Mobile Number <span>*</span>
                        </label>
                        <input
                            type="tel"
                            id="mobileNumber"
                            name="mobileNumber"
                            placeholder="Mobile Number (e.g., 7911123456)"
                            className="text-field"
                            value={formData.mobileNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="form-field">
                <label htmlFor="firstName" className="form-label">
                    First Name
                </label>
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    className="text-field"
                    value={formData.firstName}
                    onChange={handleChange}
                />
            </div>
            <div className="form-field">
                <label htmlFor="lastName" className="form-label">
                    Last Name
                </label>
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    className="text-field"
                    value={formData.lastName}
                    onChange={handleChange}
                />
            </div>
            {renderDateOfBirthDropdowns()}
            <div className="form-submit">
                <button className="default-btn mr-2" type="button" onClick={() => setStep(1)} disabled={isLoading}>
                    Back
                </button>&nbsp;&nbsp;
                <button className="default-btn" type="submit" disabled={isLoading}>
                    {isLoading ? 'Registering...' : 'Register'}
                </button>
            </div>
        </>
    );

    const renderStep3 = () => (
        <>
            <div className="form-field">
                <label htmlFor="otp" className="form-label">
                    Enter OTP <span>*</span>
                </label>
                <input
                    type="text"
                    id="otp"
                    name="otp"
                    placeholder="Enter OTP"
                    className="text-field"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                />
            </div>
            <div className="form-submit">
                <button className="default-btn" type="button" onClick={handleOtpSubmit} disabled={isLoading}>
                    {isLoading ? 'Verifying...' : 'Verify OTP'}
                </button>
            </div>
        </>
    );

    const renderStep4 = () => (
        <div className="success-message">
            <h2>Registration Successful!</h2>
            <p>Your account has been created. You can now log in to access your account.</p>
            <button className="default-btn" onClick={handleLoginAfterRegistration}>
                Log In
            </button>
        </div>
    );


    return (
        <div>
            <Header openLoginModal={openLoginModal} />
            <section className="register-section" id="register">
                <img src={middleCircle} alt="circle" className="register-middle-circle" />
                <img src={bottomCircle} alt="circle" className="register-bottom-circle" />
                <div className="container register-content">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <h1 className="section-headline-light">Register for ShootConnect</h1>
                            <h6 className="section-sub-headline-light">
                                Create your account and start your journey with ShootConnect.
                            </h6>
                            <form className="register-form" onSubmit={(e) => {
                                e.preventDefault();
                                if (step === 3) {
                                    handleOtpSubmit(e);
                                } else {
                                    handleSubmit(e);
                                }
                            }}>
                                {step === 1 && renderStep1()}
                                {step === 2 && renderStep2()}
                                {step === 3 && renderStep3()}
                                {step === 4 && renderStep4()}
                            </form>
                            {error && <p className="error-message">{error}</p>}
                            {success && <p className="success-message">{success}</p>}
                            {step < 4 && (
                                <h6 className="form-links">
                                    Already have an account?&nbsp;<button onClick={openLoginModal} className="login-link">Login here</button>
                                </h6>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <LoginModal
                isOpen={isLoginModalOpen}
                onClose={closeLoginModal}
                registrationComplete={registrationComplete}
                username={formData.username}
            />
            <Footer />
        </div>
    );
};

export default RegisterSection;