import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';
import { ApiRepos } from '../../contracts/ContractVars';
import { useUser } from '../../UserContext';
import './LoginModal.css';

const LoginModal = ({ isOpen, onClose, registrationComplete, username }) => {
    const [formData, setFormData] = useState({
        loginIdentifier: '',
        password: '',
        email: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
    const [otp, setOtp] = useState('');
    const [userMobile, setUserMobile] = useState('');
    const navigate = useNavigate();
    const { setUser } = useUser();

    useEffect(() => {
        if (registrationComplete && username) {
            // Ensure we set both username and loginIdentifier for compatibility
            setFormData(prevState => ({
                ...prevState,
                loginIdentifier: username,
                username: username // Keep for backward compatibility
            }));
        }
    }, [registrationComplete, username]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess('');

        try {
            const response = await fetch(`${ApiRepos}account/login`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    LoginIdentifier: formData.loginIdentifier,
                    Password: formData.password
                })
            });

            const data = await response.json();

            if (!response.ok) {
                if (data.error?.includes('Account not verified')) {
                    setUserMobile(data.userMobile);
                    setShowVerification(true);
                    await sendOtp(data.userMobile);
                    setSuccess('OTP sent to your mobile number');
                    return; // Don't throw error since this is an expected flow
                }
                throw new Error(data.error || 'Login failed. Please check your credentials.');
            }

            setUser(data);
            console.log(data);
            onClose();
            navigate('/dashboard/home', { replace: true });
        } catch (err) {
            console.error('Login error:', err);
            setError(err.message || 'An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await fetch(`${ApiRepos}user/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Email: formData.email
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to process password reset request.');
            }

            setForgotPasswordSuccess(true);
        } catch (err) {
            console.error('Forgot password error:', err);
            setError(err.message || 'An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const sendOtp = async (mobileNumber) => {
        try {
            const response = await fetch(`${ApiRepos}account/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobileNumber })
            });

            if (!response.ok) {
                throw new Error('Failed to send OTP');
            }

            setSuccess('OTP sent to your mobile number');
        } catch (err) {
            setError('Failed to send OTP. Please try again.');
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await fetch(`${ApiRepos}account/verify-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mobileNumber: userMobile,
                    otp: otp
                })
            });

            const data = await response.json();

            if (response.ok) {
                setSuccess('Account verified successfully! You can now log in.');
                setShowVerification(false);
                setOtp('');
                await handleSubmit(e);
            } else {
                setError(data.error || 'Invalid OTP. Please try again.');
            }
        } catch (err) {
            setError('Failed to verify OTP. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const toggleForgotPassword = () => {
        setShowForgotPassword(!showForgotPassword);
        setShowVerification(false);
        setError('');
        setSuccess('');
        setForgotPasswordSuccess(false);
    };

    const renderVerification = () => (
        <div className="verification-section">
            <h3 className="section-headline-light">Verify Your Account</h3>
            <p className="section-sub-headline-light">
                Enter the verification code sent to<br />
                <span className="font-medium">{userMobile}</span>
            </p>
            <form onSubmit={handleVerifyOtp} className="login-form">
                <div className="form-field">
                    <label htmlFor="otp" className="form-label">
                        OTP <span>*</span>
                    </label>
                    <input
                        type="text"
                        id="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="text-field"
                        placeholder="Enter OTP"
                        maxLength={6}
                        required
                    />
                </div>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
                <div className="form-submit">
                    <button type="submit" className="default-btn" disabled={isLoading}>
                        {isLoading ? 'Verifying...' : 'Verify OTP'}
                    </button>
                </div>
                <button
                    type="button"
                    onClick={() => sendOtp(userMobile)}
                    className="link-button mt-3"
                >
                    Resend OTP
                </button>
                <button
                    type="button"
                    onClick={() => setShowVerification(false)}
                    className="link-button mt-2"
                >
                    Back to Login
                </button>
            </form>
        </div>
    );

    if (!isOpen) return null;

    return (
        <div className="login-modal-overlay">
            <div className="login-modal">
                <button onClick={onClose} className="close-button">
                    <X size={24} />
                </button>
                {showVerification ? (
                    renderVerification()
                ) : (
                    <>
                        <h2 className="section-headline-light">
                            {showForgotPassword ? "Reset Password" : "Login to ShootConnect"}
                        </h2>
                        <h6 className="section-sub-headline-light">
                            {showForgotPassword
                                ? "Enter your email to reset your password."
                                : "Access your personalized dashboard and start tracking your progress today."}
                        </h6>
                        {showForgotPassword ? (
                            forgotPasswordSuccess ? (
                                <div className="success-message">
                                    <p>Password reset instructions have been sent to your email.</p>
                                    <button onClick={toggleForgotPassword} className="default-btn">
                                        Back to Login
                                    </button>
                                </div>
                            ) : (
                                <form onSubmit={handleForgotPassword} className="login-form">
                                    <div className="form-field">
                                        <label htmlFor="email" className="form-label">
                                            Email <span>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="text-field"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    {error && <p className="error-message">{error}</p>}
                                    <div className="form-submit">
                                        <button type="submit" className="default-btn" disabled={isLoading}>
                                            {isLoading ? 'Processing...' : 'Reset Password'}
                                        </button>
                                    </div>
                                </form>
                            )
                        ) : (
                            <form onSubmit={handleSubmit} className="login-form">
                                <div className="form-field">
                                    <label htmlFor="loginIdentifier" className="form-label">
                                        Email or Username <span>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="loginIdentifier"
                                        name="loginIdentifier"
                                        className="text-field"
                                        value={formData.loginIdentifier}
                                        onChange={handleChange}
                                        placeholder="Enter your email or username"
                                        required
                                    />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="password" className="form-label">
                                        Password <span>*</span>
                                    </label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="text-field"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder="Enter your password"
                                        required
                                    />
                                </div>
                                {error && <p className="error-message">{error}</p>}
                                {success && <p className="success-message">{success}</p>}
                                <div className="form-submit">
                                    <button type="submit" className="default-btn" disabled={isLoading}>
                                        {isLoading ? 'Logging in...' : 'Login'}
                                    </button>
                                </div>
                            </form>
                        )}
                        <div className="form-links">
                            {showForgotPassword ? (
                                <button type="button" onClick={toggleForgotPassword} className="link-button">
                                    Back to Login
                                </button>
                            ) : (
                                <>
                                    <button type="button" onClick={toggleForgotPassword} className="link-button">
                                        Forgot Password
                                    </button>
                                    <span className="text-sm text-gray-500 mx-2">or</span>
                                    <Link to="/register" className="link-button">
                                        Register
                                    </Link>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginModal;