import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../contracts/ContractVars';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import LoginModal from '../../components/loginSection/LoginModal';
import './ResetPassword.css';

const ResetPassword = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const openLoginModal = () => setIsLoginModalOpen(true);
    const closeLoginModal = () => setIsLoginModalOpen(false);

    useEffect(() => {
        // Extract the raw token from the URL
        const rawToken = location.search.split('token=')[1];
        if (rawToken) {
            setToken(rawToken);
        } else {
            setError('Invalid reset link');
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            const response = await fetch(`${ApiRepos}user/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Token: token,
                    NewPassword: password
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to reset password');
            }

            setSuccess(true);
            // Redirect to home page after a short delay
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } catch (err) {
            setError(err.message);
        }
    };

    if (success) {
        return <div className="success-message">Password reset successful. Redirecting to home page...</div>;
    }

    return (
        <div>
            <Header openLoginModal={openLoginModal} />
            <section className="reset-password-section">
                <img src="/path-to-your-middle-circle-image.png" alt="" className="reset-password-middle-circle" />
                <img src="/path-to-your-bottom-circle-image.png" alt="" className="reset-password-bottom-circle" />
                <div className="reset-password-content">
                    <h2 className="section-headline-light">Reset Password</h2>
                    <p className="section-sub-headline-light">Enter your new password below</p>
                    {error && <p className="error-message">{error}</p>}
                    <form onSubmit={handleSubmit} className="reset-password-form">
                        <div className="form-field">
                            <label htmlFor="password" className="form-label">New Password <span>*</span></label>
                            <input
                                type="password"
                                id="password"
                                className="text-field"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-field">
                            <label htmlFor="confirmPassword" className="form-label">Confirm New Password <span>*</span></label>
                            <input
                                type="password"
                                id="confirmPassword"
                                className="text-field"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-submit">
                            <button type="submit" className="default-btn">Reset Password</button>
                        </div>
                    </form>
                    <div className="form-links">
                        Remember your password? <button onClick={openLoginModal} className="login-link">Login here</button>
                    </div>
                </div>
            </section>
            <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
            <Footer />
        </div>
    );
};

export default ResetPassword;