import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import './ClubsDashboard.css';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';


const FavouriteClubCard = ({ club, onRemove }) => {
    const navigate = useNavigate();

    return (
        <div className="col-md-6 dashboard-card-container">
            <div className="dashboard-card">
                <h3 className="dashboard-card-headline">{club.name}</h3>
                <h6 className="dashboard-card-describer">{club.address}</h6>
                <div className="dashboard-card-cta mt-5">
                    <button
                        className="dashboard-card-outline-btn"
                        onClick={() => navigate(`/dashboard/clubs/${club.clubId}`)}
                    >
                        View Details
                    </button>
                    <button
                        className="dashboard-card-default-btn"
                        onClick={() => onRemove(club.clubId)}
                    >
                        Remove
                    </button>
                </div>
            </div>
        </div>
    );
};

const Clubs = () => {
    const navigate = useNavigate();
    const [tab, setTab] = useState(1)
    const [favoriteClubs, setFavoriteClubs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null);

    const fetchFavoriteClubs = useCallback(async () => {
        setError(null);
        try {
            const response = await fetch(`${ApiRepos}clubs/favorite`, {
                method: 'GET',
                credentials: 'include',
            });
            if (!response.ok) throw new Error('Failed to fetch favorite clubs');
            const data = await response.json();
            setFavoriteClubs(data);
        } catch (error) {
            console.error('Error fetching favorite clubs:', error);
            setError('Failed to load favorite clubs. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchFavoriteClubs();
    }, [fetchFavoriteClubs]);

    const handleSearch = async () => {
        if (!searchTerm.trim()) return;

        setError(null);
        try {
            const response = await fetch(`${ApiRepos}clubs/search?searchTerm=${encodeURIComponent(searchTerm)}`, {
                method: 'GET',
                credentials: 'include',
            });
            if (!response.ok) throw new Error('Failed to search for clubs');
            const data = await response.json();
            setSearchResults(data);
        } catch (error) {
            console.error('Error searching for clubs:', error);
            setError('Failed to search for clubs. Please try again.');
        }
    };

    const handleSearchKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleAddToFavorites = async (clubId) => {
        setError(null);
        try {
            const response = await fetch(`${ApiRepos}clubs/favorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ clubId }),
            });
            if (!response.ok) throw new Error('Failed to add club to favorites');
            await fetchFavoriteClubs();
            setSearchResults(prevResults =>
                prevResults.filter(club => club.clubId !== clubId)
            );
        } catch (error) {
            console.error('Error adding club to favorites:', error);
            setError('Failed to add club to favorites. Please try again.');
        }
    };

    const handleRemoveFavorite = async (clubId) => {
        setError(null);
        try {
            const response = await fetch(`${ApiRepos}clubs/favorite/${clubId}`, {
                method: 'DELETE',
                credentials: 'include',
            });
            if (!response.ok) throw new Error('Failed to remove club from favorites');
            await fetchFavoriteClubs();
        } catch (error) {
            console.error('Error removing club from favorites:', error);
            setError('Failed to remove club from favorites. Please try again.');
        }
    };


    //////// GOOGLE MAPS

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
        border: '1px solid #FF6A00',
        borderRadius: 10,
        marginTop: 30
    };

    const center = {
        lat: 40.712776,
        lng: -74.005974
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyALA_AFujj-whDPOCZyGcyaLOJZ34qNa94"
    });

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;

    //////
    if (isLoading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
            </div>
        );
    }
    

    return (
        <div className="dashboard-component">
            <div className="dashboard-heading">
                <h1>Shooting Clubs</h1>
                <h2>Find and manage your favorite shooting clubs</h2>
            </div>

            {error && (
                <div className="error-message" role="alert">
                    <p>{error}</p>
                </div>
            )}

            <div className="row">
                <div className="col-lg-9">

                    <div className="dashboard-tabs">
                        <button className={`dashboard-tab ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>Find Clubs</button>
                        <button className={`dashboard-tab ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>Favorites</button>
                    </div>

                    {
                        tab === 1 && (
                            <div className="search-section">

                                <div className="dashboard-search-container">
                                    <div className="dashboard-search-field">
                                        <input 
                                            type="text" 
                                            placeholder='Seach by club name or location...' 
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            onKeyPress={handleSearchKeyPress}
                                        />
                                    </div>
                                    <button 
                                        className='dashboard-search-submit'
                                        onClick={handleSearch}
                                    >Search</button>
                                </div>

                                {searchResults.length > 0 && (
                                    <div className="row">
                                        {searchResults.map(club => (
                                            <div key={club.clubId} className="col-md-6 dashboard-card-container">
                                                <div className="dashboard-card">
                                                    <h3 className="dashboard-card-headline">{club.name}</h3>
                                                    <h2 className="dashboard-card-describer">{club.address}</h2>
                                                    <div className="dashboard-card-cta mt-5">
                                                        <button
                                                            className="dashboard-card-outline-btn"
                                                            onClick={() => navigate(`/dashboard/clubs/${club.clubId}`)}
                                                        >
                                                            View Details
                                                        </button>
                                                        <button
                                                            className="dashboard-card-default-btn"
                                                            onClick={() => handleAddToFavorites(club.clubId)}
                                                            disabled={favoriteClubs.some(fc => fc.clubId === club.clubId)}
                                                        >
                                                            {favoriteClubs.some(fc => fc.clubId === club.clubId)
                                                                ? 'Added to Favorites'
                                                                : 'Add to Favorites'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )
                    }

                    {
                        tab === 2 && (
                            <div className="favorites-section">
                                <div className="row">
                                    {favoriteClubs.length > 0 ? (
                                        favoriteClubs.map(club => (
                                            <FavouriteClubCard
                                                key={club.clubId}
                                                club={club}
                                                onRemove={handleRemoveFavorite}
                                            />
                                        ))
                                    ) : (
                                        <p className="no-clubs-message">You haven't added any favorite clubs yet.</p>
                                    )}
                                </div>
                            </div>
                        )
                    }

                </div>
                <div className="col-lg-3">
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={10}
                    >
                        <Marker position={center} />
                    </GoogleMap>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d636195.1089344992!2d-0.8765601152710372!3d51.47399690276635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sshooting%20clubs%20london!5e0!3m2!1sen!2sma!4v1731751990586!5m2!1sen!2sma" 
                        style={mapContainerStyle} 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade"
                        title="Google Map showing shooting clubs"
                    ></iframe>
                </div>
            </div>

        </div>
    );
};

export default Clubs;