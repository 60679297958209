import React from 'react';
import './Download.css';
import heroImageMobile from '../../../assets/images/hero-img-1.png'; // Mobile view image from hero section
import googlePlay from '../../../assets/images/google-play.svg';
import appStore from '../../../assets/images/app-store.svg';
import pdfIcon from '../../../assets/icons/pdf-icon.svg'; // Placeholder icon for PDF downloads

const Download = () => {
    return (
        <div className='dashboard-component'>
            <div className="dashboard-heading">
                <h1>Download ShootConnect App</h1>
                <h2>Enhance your shooting experience with the ShootConnect mobile app.</h2>
            </div>
            
            <div className="download-image">
                <img src={heroImageMobile} alt="Mobile Preview" />
            </div>

            <div className="download-app-links">
                <a href="https://example.com/download/google-play" target="_blank" rel="noopener noreferrer">
                    <img src={googlePlay} alt="Google Play" />
                </a>
                <a href="https://example.com/download/app-store" target="_blank" rel="noopener noreferrer">
                    <img src={appStore} alt="App Store" />
                </a>
            </div>

            <h4 className='dashboard-component-title'>Download Score Sheets</h4>
            <p>Download or print PDF versions of your score sheets for easy access.</p>
            <div className="download-pdf-links">
                <a href="https://example.com/download/score-sheet-1" target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt="PDF Icon" /> Score Sheet 1 (PDF)
                </a>
                <a href="https://example.com/download/score-sheet-2" target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt="PDF Icon" /> Score Sheet 2 (PDF)
                </a>
            </div>
        </div>
    );
};

export default Download;
