import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import hitIcon from '../../../assets/icons/hit.png';
import missIcon from '../../../assets/icons/miss.png';
import backIcon from '../../../assets/icons/back-arrow.png';
import { ApiRepos } from '../../../contracts/ContractVars';
import './DashboardScore.css';
import './ScoreShoot.css';

const ScoreShoot = () => {
    const { scoreSheetId } = useParams();
    const navigate = useNavigate();
    const [scoreSheet, setScoreSheet] = useState(null);
    const [rounds, setRounds] = useState([]);
    const [selectedCase, setSelectedCase] = useState([0, 0]); // [roundIndex, shotIndex]
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchScoreSheet = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${ApiRepos}scoresheet/${scoreSheetId}`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch score sheet');
            const data = await response.json();
            console.log('Fetched data:', data);
            setScoreSheet(data);
            setRounds(data.rounds || []);
        } catch (error) {
            console.error('Error fetching score sheet:', error);
            setError('Failed to load score sheet. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [scoreSheetId]);

    useEffect(() => {
        if (scoreSheetId) {
            fetchScoreSheet();
        }
    }, [scoreSheetId, fetchScoreSheet]);

    const addScore = async (result) => {
        const [roundIndex, shotIndex] = selectedCase;
        const updatedRounds = [...rounds];
        const round = updatedRounds[roundIndex];
        const scoreData = JSON.parse(round.scoreData);

        scoreData.scores[shotIndex] = result;
        round.scoreData = JSON.stringify(scoreData);

        try {
            const response = await fetch(`${ApiRepos}scoresheet/${scoreSheetId}/rounds/${round.roundId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ scoreData: round.scoreData }),
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to update round');

            setRounds(updatedRounds);
            moveToNextCase();
        } catch (error) {
            console.error('Error updating round:', error);
            setError('Failed to update score. Please try again.');
        }
    };

    const moveToNextCase = useCallback(() => {
        const [roundIndex, shotIndex] = selectedCase;
        const round = rounds[roundIndex];
        const scoreData = JSON.parse(round.scoreData);

        // Handle pattern-based scoring if available
        if (scoreData.pattern) {
            const currentPatternIndex = scoreData.pattern.findIndex(p => {
                const startIndex = p.sequence * p.shots.length;
                const endIndex = startIndex + p.shots.length;
                return shotIndex >= startIndex && shotIndex < endIndex;
            });

            if (currentPatternIndex >= 0) {
                const currentPattern = scoreData.pattern[currentPatternIndex];
                const shotWithinPattern = shotIndex % currentPattern.shots.length;

                if (shotWithinPattern < currentPattern.shots.length - 1) {
                    // Move to next shot within current pattern
                    setSelectedCase([roundIndex, shotIndex + 1]);
                } else if (currentPatternIndex < scoreData.pattern.length - 1) {
                    // Move to first shot of next pattern
                    const nextPattern = scoreData.pattern[currentPatternIndex + 1];
                    setSelectedCase([roundIndex, nextPattern.sequence * nextPattern.shots.length]);
                } else {
                    // Move to next round
                    const nextRound = (roundIndex + 1) % rounds.length;
                    setSelectedCase([nextRound, 0]);
                }
            }
        } else {
            // Legacy format handling
            let nextRound = roundIndex;
            let nextShot = shotIndex + 1;

            if (nextShot >= scoreData.totalShots) {
                nextShot = 0;
                nextRound = (nextRound + 1) % rounds.length;
            }

            setSelectedCase([nextRound, nextShot]);
        }
    }, [selectedCase, rounds]);

    const renderShootingPattern = (round, roundIndex) => {
        const scoreData = JSON.parse(round.scoreData);
        const variant = scoreSheet?.variant?.toLowerCase() || '';

        if (!scoreData.pattern) {
            return renderLegacyPattern(round, roundIndex, scoreData);
        }

        switch (variant) {
            case 'englishskeet':
            case 'olympicskeet':
            case 'olympicskeetpre2012':
            case 'olympicskeetfinal':
            case 'americanskeet':
                return renderSkeetPattern(round, roundIndex, scoreData);
            case 'downtheline':
            case 'olympictrap':
            case 'americantrap':
                return renderTrapPattern(round, roundIndex, scoreData);
            case 'englishsporting':
            case 'fitascsporting':
            case 'compaksporting':
                return renderSportingPattern(round, roundIndex, scoreData);
            default:
                return renderLegacyPattern(round, roundIndex, scoreData);
        }
    };

    const renderLegacyPattern = (round, roundIndex, scoreData) => {
        return (
            <div className="shooter-cases legacy-pattern">
                {scoreData.scores.map((score, shotIndex) => (
                    <div
                        key={shotIndex}
                        className={`shooter-case ${score !== null ? 'active' : ''} 
                            ${(selectedCase[0] === roundIndex && selectedCase[1] === shotIndex) ? 'selected' : ''}`}
                        onClick={() => setSelectedCase([roundIndex, shotIndex])}
                    >
                        {score === true && <img src={hitIcon} alt="Hit" />}
                        {score === false && <img src={missIcon} alt="Miss" />}
                        <span className="shot-number">{shotIndex + 1}</span>
                    </div>
                ))}
            </div>
        );
    };

    const renderSkeetPattern = (round, roundIndex, scoreData) => {
        // Group patterns by station while maintaining sequence order
        const stations = scoreData.pattern.reduce((acc, pattern) => {
            if (!acc[pattern.station]) {
                acc[pattern.station] = [];
            }
            acc[pattern.station][pattern.sequence] = pattern;
            return acc;
        }, {});

        return (
            <div className="shooter-cases skeet-pattern">
                {Object.entries(stations).map(([station, patterns]) => (
                    <div key={station} className="station-group">
                        <div className="station-label">{station}</div>
                        <div className="shots-group">
                            {patterns.filter(Boolean).flatMap((pattern) => (
                                pattern.shots.map((shot, shotIndex) => {
                                    const absoluteIndex = pattern.sequence * pattern.shots.length + shotIndex;
                                    return (
                                        <div
                                            key={`${station}-${pattern.sequence}-${shotIndex}`}
                                            className={`shooter-case ${shot.toLowerCase()} 
                                            ${scoreData.scores[absoluteIndex] !== null ? 'active' : ''} 
                                            ${selectedCase[0] === roundIndex &&
                                                    selectedCase[1] === absoluteIndex ? 'selected' : ''}
                                            ${scoreData.scores[absoluteIndex] === true ? 'hit' : ''}
                                            ${scoreData.scores[absoluteIndex] === false ? 'miss' : ''}`}
                                            onClick={() => setSelectedCase([roundIndex, absoluteIndex])}
                                        >
                                            {scoreData.scores[absoluteIndex] === true && <img src={hitIcon} alt="Hit" />}
                                            {scoreData.scores[absoluteIndex] === false && <img src={missIcon} alt="Miss" />}
                                            <span className="house-indicator">{shot}</span>
                                        </div>
                                    );
                                })
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderTrapPattern = (round, roundIndex, scoreData) => {
        const stations = scoreData.pattern.reduce((acc, pattern) => {
            if (!acc[pattern.station]) {
                acc[pattern.station] = [];
            }
            acc[pattern.station].push(pattern);
            return acc;
        }, {});

        return (
            <div className="shooter-cases trap-pattern">
                <div className="trap-stations">
                    {Object.entries(stations).map(([station, patterns]) => (
                        <div key={station} className="station-group">
                            <div className="station-label">Stand {station}</div>
                            <div className="shots-row">
                                {patterns.map((pattern, index) => {
                                    const absoluteIndex = pattern.sequence - 1;
                                    return (
                                        <div
                                            key={`${station}-${index}`}
                                            className={`shooter-case 
                                            ${scoreData.scores[absoluteIndex] !== null ? 'active' : ''} 
                                            ${selectedCase[0] === roundIndex &&
                                                    selectedCase[1] === absoluteIndex ? 'selected' : ''}
                                            ${scoreData.scores[absoluteIndex] === true ? 'hit' : ''}
                                            ${scoreData.scores[absoluteIndex] === false ? 'miss' : ''}`}
                                            onClick={() => setSelectedCase([roundIndex, absoluteIndex])}
                                        >
                                            {scoreData.scores[absoluteIndex] === true && <img src={hitIcon} alt="Hit" />}
                                            {scoreData.scores[absoluteIndex] === false && <img src={missIcon} alt="Miss" />}
                                            <span className="shot-number">{absoluteIndex + 1}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSportingPattern = (round, roundIndex, scoreData) => {
        return (
            <div className="shooter-cases sporting-pattern">
                {scoreData.pattern.map((pattern, index) => {
                    const absoluteIndex = pattern.sequence * pattern.shots.length;
                    return (
                        <div key={index} className="station-group">
                            <div className="station-label">{pattern.station}</div>
                            <div className="shots-group">
                                {pattern.shots.map((target, shotIndex) => {
                                    const currentIndex = absoluteIndex + shotIndex;
                                    return (
                                        <div
                                            key={shotIndex}
                                            className={`shooter-case 
                                            ${pattern.type.toLowerCase()} 
                                            ${scoreData.scores[currentIndex] !== null ? 'active' : ''} 
                                            ${selectedCase[0] === roundIndex &&
                                                    selectedCase[1] === currentIndex ? 'selected' : ''}
                                            ${scoreData.scores[currentIndex] === true ? 'hit' : ''}
                                            ${scoreData.scores[currentIndex] === false ? 'miss' : ''}`}
                                            onClick={() => setSelectedCase([roundIndex, currentIndex])}
                                        >
                                            {scoreData.scores[currentIndex] === true && <img src={hitIcon} alt="Hit" />}
                                            {scoreData.scores[currentIndex] === false && <img src={missIcon} alt="Miss" />}
                                            <span className="shot-label">{target}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    

    if (isLoading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">Error: {error}</div>;
    if (!scoreSheet || !rounds || rounds.length === 0) {
        return <div className="error">No score sheet or rounds found.</div>;
    }

    return (
        <div className='dashboard-component score-shoot'>
            <div className="dashboard-component-headline">
                <button className="back-button" onClick={() => navigate(-1)}>
                    <img src={backIcon} alt="Back" />
                    Back to Score Sheets
                </button>
                <h1 className="dashboard-component-headline-title">{scoreSheet.eventName || 'Unnamed Event'}</h1>
                <h2>{scoreSheet.disciplineType || 'Unknown Discipline'} - {scoreSheet.variant || 'Unknown Variant'}</h2>
            </div>

            <DragDropContext onDragEnd={(result) => console.log('Drag ended:', result)}>
                <Droppable droppableId="rounds">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="rounds-container">
                            {rounds.map((round, roundIndex) => (
                                <Draggable
                                    key={round.roundId}
                                    draggableId={`round-${round.roundId}`}
                                    index={roundIndex}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="scoring-sheet"
                                        >
                                            <h3>Round {round.roundNumber} - {round.shooterName}</h3>
                                            {renderShootingPattern(round, roundIndex)}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className="scoring-btns">
                <button className="scoring-miss-btn" onClick={() => addScore(false)}>
                    <img src={missIcon} alt="Miss" />
                    Miss
                </button>
                <button className="scoring-hit-btn" onClick={() => addScore(true)}>
                    <img src={hitIcon} alt="Hit" />
                    Hit
                </button>
            </div>
        </div>
    );
};

export default ScoreShoot;