import React, { useState } from 'react';
import { ApiRepos } from '../../../../contracts/ContractVars';
import ClubSelector from './ClubSelector';

const CreateEvent = ({ isOpen, closeModal, onEventCreated }) => {
    const [eventData, setEventData] = useState({
        name: '',
        clubId: '',
        eventDate: new Date().toISOString().split('T')[0],
        eventTime: new Date().toTimeString().slice(0, 8),
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEventData(prev => ({ ...prev, [name]: value }));
    };

    const handleClubSelect = (club) => {
        setEventData(prev => ({
            ...prev,
            clubId: club.clubId,
            clubName: club.name
        }));
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            const response = await fetch(`${ApiRepos}events`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(eventData)
            });
            if (!response.ok) throw new Error('Failed to create event');
            const createdEvent = await response.json();
            onEventCreated(createdEvent);
            closeModal();
        } catch (error) {
            console.error('Error creating event:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="create-event-container active">
            <div className="create-event-overlay" onClick={closeModal}></div>
            <div className="create-event-box">
                <div className="modal-header">
                    <h2>New Event</h2>
                </div>
                <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Name</label>
                                <div className="dashboard-form-field">
                                    <input
                                        type="text"
                                        name="name"
                                        value={eventData.name}
                                        onChange={handleInputChange}
                                        placeholder='Event Name'
                                        className='create-event-input'
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Club</label>
                                <ClubSelector
                                    selectedClub={eventData.clubId ? {
                                        id: eventData.clubId,
                                        name: eventData.clubName
                                    } : null}
                                    onClubSelect={handleClubSelect}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Date</label>
                                <div className="dashboard-form-field">
                                    <input
                                        type="date"
                                        name="eventDate"
                                        value={eventData.eventDate}
                                        onChange={handleInputChange}
                                        className='create-event-input'
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Time</label>
                                <div className="dashboard-form-field">
                                    <input
                                        type="time"
                                        name="eventTime"
                                        value={eventData.eventTime}
                                        onChange={handleInputChange}
                                        className='create-event-input'
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-ctas">
                        <button type="button" className="dashboard-outline-btn me-2" onClick={closeModal}>
                            Cancel
                        </button>
                        <button type="submit" className="dashboard-default-btn" disabled={isSubmitting}>
                            {isSubmitting ? 'Creating...' : 'Create Event'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateEvent;