import React, { useState } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import chrisb1 from '../../assets/images/chrisburgessshoot1.jpg'
import LoginModal from '../../components/loginSection/LoginModal';

const About = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const openLoginModal = () => setIsLoginModalOpen(true);
    const closeLoginModal = () => setIsLoginModalOpen(false);

    return (
        <div>
            <Header openLoginModal={openLoginModal} />
            <section id="about" className="about-section">
                <div className="container">            
                    <h2 className="section-title">About Shoot Connect</h2>
                    <div className="about-content">
                        <div className="platform-info">                            
                            <h3>Our Platform</h3>
                            <p>Shoot Connect is a revolutionary platform designed to bring together shooting sports enthusiasts, clubs, and event organizers. We provide a centralized hub for managing memberships, organizing events, and fostering a vibrant community of shooters across the UK.</p>
                            <ul>
                                <li>Easy event management and participation</li>
                                <li>Streamlined club membership administration</li>
                                <li>Real-time score tracking and leaderboards</li>
                                <li>Community forums for discussion and knowledge sharing</li>
                            </ul>
                        </div>
                        <div className="founder-card">
                            <div className="founder-image">
                                <img src={chrisb1} alt="Chris Burgess" />
                            </div>
                            <h3>Chris Burgess</h3>
                            <p>Founder & CEO</p>
                            <p>With a passion for shooting sports both game & clay, and a background in technology, Chris Burgess founded Shoot Connect to revolutionise the way shooting enthusiasts connect and engage with their sport. His vision is to create a thriving digital ecosystem that enhances the shooting sports experience for everyone involved.</p>
                        </div>
                    </div>
                    <div className="company-mission">
                        <h3>Our Mission</h3>
                        <p>At Shoot Connect, we're committed to advancing the shooting sports community by leveraging technology to create more accessible, engaging, and efficient experiences for shooters, clubs, and event organisers alike. Our goal is to become the go-to platform for all things related to shooting sports in the UK and beyond.</p>
                    </div>
                </div>
            </section>
            <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
            <Footer />
        </div>
    );
};

export default About;