import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Subscription.css';

const SubscriptionSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/subscription');
        }, 5000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="subscription-page">
            <h2>Subscription Successful!</h2>
            <div className="current-subscription">
                <h3>Thank you for subscribing to our VIP/Premium plan.</h3>
                <p>Your account will be updated shortly with the new features.</p>
                <p>You will be redirected to your subscription page in a few seconds.</p>
            </div>
            <div className="subscription-tiers">
                <h3>What's Next?</h3>
                <div className="tier-container">
                    <div className="tier">
                        <h4>Enjoy Your Premium Benefits</h4>
                        <ul>
                            <li>Full functionality unlocked</li>
                            <li>Unlimited access to all features</li>
                            <li>Priority support available</li>
                            <li>Advanced analytics at your fingertips</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionSuccess;