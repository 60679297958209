import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import {
    MapPin,
    Phone,
    Mail,
    Globe,
    Coffee,
    Store,
    Car,
    Bath,
    MoveLeft,
    CalendarDays,
    Users
} from 'lucide-react';
import './ClubsDashboard.css';

const ClubView = () => {
    const { clubId } = useParams();
    const navigate = useNavigate();
    const [club, setClub] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(1)

    useEffect(() => {
        const fetchClubDetails = async () => {
            try {
                const response = await fetch(`${ApiRepos}clubs/${clubId}`, {
                    credentials: 'include',
                });

                if (!response.ok) throw new Error('Failed to fetch club details');
                const data = await response.json();
                setClub(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (clubId) {
            fetchClubDetails();
        }
    }, [clubId]);

    if (loading) {
        return <div className="loading-container"><div className="loading-spinner"></div></div>;
    }

    if (error) {
        return (
            <div className="error-container">
                <p className="error-message">{error}</p>
                <button className="btn btn-primary" onClick={() => navigate(-1)}>
                    Go Back
                </button>
            </div>
        );
    }

    if (!club) return null;

    return (
        <div className="dashboard-component">
            {/* Header Section */}
            <div className="club-header">
                <button className="dashboard-back-button" onClick={() => navigate(-1)}>
                    <MoveLeft className='me-2' /> Back to Clubs
                </button>
                <div className="dashboard-heading">
                    <h1>{club.name}</h1>
                </div>
            </div>

            <div className="dashboard-tabs">
                <button className={`dashboard-tab ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>About</button>
                <button className={`dashboard-tab ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>Stands</button>
                <button className={`dashboard-tab ${tab === 3 && 'active'}`} onClick={() => setTab(3)}>Photos</button>
                <button className={`dashboard-tab ${tab === 4 && 'active'}`} onClick={() => setTab(4)}>Maps</button>
                <button className={`dashboard-tab ${tab === 5 && 'active'}`} onClick={() => setTab(5)}>Events</button>
            </div>

            <div className="row">
                <div className="col-lg-8">
                    {
                        tab === 1 && (
                            <>
                                {club.description && (
                                    <>
                                        <h4 className='club-heading'>Description</h4>
                                        <p className="club-describer">{club.description}</p>
                                    </>
                                )}
                                {club.introduction && (
                                    <>
                                        <h4 className='club-heading'>Introduction</h4>
                                        <p className="club-describer">{club.introduction}</p>
                                    </>
                                )}
                            </>
                        )
                    }
                    {
                        tab === 2 && (
                            <div className="row">
                                {club.stands?.map((stand) => (
                                    <div key={stand.standId} className="col-md-6 dashboard-card-container">
                                        <div className="dashboard-card">
                                            {stand.photos?.[0] && (
                                                <img
                                                    src={stand.photos[0].photoUrl}
                                                    className="club-view-card-image"
                                                    alt=''
                                                />
                                            )}
                                            <div className="club-view-card-heading">
                                                <h2>{stand.name}</h2>
                                                <h6>{stand.standType}</h6>
                                            </div>
                                            <p className="club-view-card-describer">{stand.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }
                    {
                        tab === 3 && (
                            <div className="row">
                                {club.photos?.map((photo) => (
                                    <div key={photo.clubPhotoId} className="col-md-6 dashboard-card-container">
                                        <div className="dashboard-card">
                                            <img
                                                src={photo.photoUrl}
                                                alt={photo.description || 'Club photo'}
                                                className="club-view-card-image"
                                            />
                                            {photo.description && <p className="club-view-card-describer">{photo.description}</p>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }
                    {
                        tab === 4 && (
                            <div className="row">
                                {club.maps?.map((map) => (
                                    <div key={map.clubMapId} className="col-md-6 dashboard-card-container">
                                        <div className="dashboard-card">
                                            <img
                                                src={map.mapUrl}
                                                alt={map.description || 'Club photo'}
                                                className="club-view-card-image"
                                            />
                                            {map.description && <p className="club-view-card-describer">{map.description}</p>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }
                    {
                        tab === 5 && (
                            <div className="row">
                                {club.events?.map((event) => (
                                    <div key={event.eventId} className="col-md-6 dashboard-card-container">
                                        <div className="dashboard-card">
                                            <div className="club-view-card-heading mt-0 mb-3">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <div className="club-view-card-info">
                                                <CalendarDays size={16} className='club-view-card-info-icon' /> <span>{new Date(event.eventDate).toLocaleDateString()}</span>
                                            </div>
                                            {event.maxParticipants && (
                                                <div className="club-view-card-info">
                                                    <Users size={16} className='club-view-card-info-icon' /> <span>{event.maxParticipants}</span>
                                                </div>
                                            )}
                                            <p className="club-view-card-describer">{event.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }
                </div>
                <div className="col-lg-4 dashboard-card-container">
                    <div className="dashboard-card">
                        <h1 className="club-info-title">General Info</h1>
                        <div className="club-info-item">
                            <MapPin size={18} className='club-info-item-icon' />
                            <span className='club-info-item-text'>{club.address}</span>
                        </div>
                        <div className="club-info-item">
                            <Phone size={18} className='club-info-item-icon' />
                            <a href={`tel:${club.phoneNumber}`} className='club-info-item-text'>{club.phoneNumber}</a>
                        </div>
                        <div className="club-info-item">
                            <Mail size={18} className='club-info-item-icon' />
                            <a href={`mailto:${club.email}`} className='club-info-item-text'>{club.email}</a>
                        </div>
                        <div className="club-info-item">
                            <Globe size={18} className='club-info-item-icon' />
                            <a 
                                href={club.website.startsWith('http') ? club.website : `https://${club.website}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='club-info-item-text'
                            >
                                {club.website.startsWith('http') ? club.website : `https://${club.website}`}
                            </a>
                        </div>
                        <h1 className="club-info-title mt-4">Facilities</h1>
                        {club.hasCafe && (
                            <div className="club-info-item">
                                <Coffee size={18} className='club-info-item-icon' />
                                <span className='club-info-item-text'>Cafe</span>
                            </div>
                        )}
                        {club.hasShop && (
                            <div className="club-info-item">
                                <Store size={18} className='club-info-item-icon' />
                                <span className='club-info-item-text'>Shop</span>
                            </div>
                        )}
                        {club.hasCarpark && (
                            <div className="club-info-item">
                                <Car size={18} className='club-info-item-icon' />
                                <span className='club-info-item-text'>Carpark</span>
                            </div>
                        )}
                        {club.hasToilets && (
                            <div className="club-info-item">
                                <Bath size={18} className='club-info-item-icon' />
                                <span className='club-info-item-text'>Toilet</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ClubView;