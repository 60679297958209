import React, { useState, useEffect, useCallback } from 'react';
import './DashboardScore.css';
import CreateEvent from './components/CreateEvent';
import EditEvent from './components/EditEvent';
import EventCard from './components/EventCard';
import EditShooters from './components/EditShooters';
import ScoreSheetManagement from './components/ScoreSheetManagement';
import { ApiRepos } from '../../../contracts/ContractVars';
import { Plus } from 'lucide-react';

const DashboardScore = () => {
    
    const [events, setEvents] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editEventModalOpen, setEditEventModalOpen] = useState(false);
    const [editShootersModalOpen, setEditShootersModalOpen] = useState(false);
    const [scoreSheetManagementOpen, setScoreSheetManagementOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchEvents = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${ApiRepos}events`, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch events');
            const data = await response.json();
            setEvents(data);
        } catch (error) {
            console.error('Error fetching events:', error);
            setError('Failed to load events. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    const closeCreateModal = () => setCreateModalOpen(false);
    const closeEditEventModal = () => setEditEventModalOpen(false);
    const openEditEventModal = (event) => {
        setSelectedEvent(event);
        setEditEventModalOpen(true);
    };
    const closeEditShootersModal = () => setEditShootersModalOpen(false);
    const openEditShootersModal = (event) => {
        setSelectedEvent(event);
        setEditShootersModalOpen(true);
    };
    const openScoreSheetManagement = (event) => {
        setSelectedEvent(event);
        setScoreSheetManagementOpen(true);
    };
    const closeScoreSheetManagement = () => setScoreSheetManagementOpen(false);

    const handleCreateEvent = (newEvent) => {
        setEvents(prevEvents => [newEvent, ...prevEvents]);
        closeCreateModal();
    };

    const handleUpdateEvent = async (updatedEvent) => {
        try {
            const response = await fetch(`${ApiRepos}events/${updatedEvent.eventId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(updatedEvent)
            });
            if (!response.ok) throw new Error('Failed to update event');
            const updatedEventData = await response.json();

            setEvents(prevEvents =>
                prevEvents.map(event =>
                    event.eventId === updatedEventData.eventId ? updatedEventData : event
                )
            );

            closeEditEventModal();
        } catch (error) {
            console.error('Error updating event:', error);
            setError('Failed to update event. Please try again.');
        }
    };

    const handleDeleteEvent = async (eventId) => {
        const event = events.find(e => e.eventId === eventId);
        if (window.confirm(`Are you sure you want to delete the event "${event?.name}"?`)) {
            try {
                setError(null); // Clear any existing errors
                setIsLoading(true);

                const response = await fetch(`${ApiRepos}events/${eventId}`, {
                    method: 'DELETE',
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to delete event');
                }

                // Remove the event from state
                await fetchEvents();

                // Show success message
                const successMessage = `Event "${event?.name}" has been successfully deleted`;
                setError({ message: successMessage, type: 'success' });

                // Clear success message after 3 seconds
                setTimeout(() => {
                    setError(null);
                }, 3000);

            } catch (error) {
                console.error('Error deleting event:', error);
                setError({ message: 'Failed to delete event. Please try again.', type: 'error' });
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (

        <div className='dashboard-component'>
            <div className="dashboard-heading">
                <h1>Events</h1>
                <h2>Score your shoots, whether it's a competition day, or you are just out for a fun shoot or training session.</h2>
            </div>
            <div className="d-flex justify-content-end">
                <button className="dashboard-default-btn" onClick={() => setCreateModalOpen(true)}>
                    <Plus size={20} className='me-2' />
                    <span>Create Event</span>
                </button>
            </div>

            <div>
                {isLoading && <div>Loading events...</div>}
                {error && (
                    <div className={error.type === 'success' ? 'success-message' : 'error-message'}>
                        {error.message}
                    </div>
                )}
            </div>

            <div className="row">
                {events.map(event => (
                    <div key={event.eventId} className="col-lg-4 col-md-6 dashboard-card-container">
                        <EventCard
                            event={{
                                id: event.eventId,
                                name: event.name,
                                eventTime: event.eventTime,
                                eventDate: event.eventDate,
                                clubName: event.clubName,
                                clubId: event.clubId
                            }}
                            onEdit={() => openEditEventModal(event)}
                            onEditShooters={() => openEditShootersModal(event)}
                            onDelete={() => handleDeleteEvent(event.eventId)}
                            onManageScoreSheets={() => openScoreSheetManagement(event)}
                        />
                    </div>
                ))}
            </div>

            <CreateEvent
                isOpen={createModalOpen}
                closeModal={closeCreateModal}
                onEventCreated={handleCreateEvent}
            />
            <EditEvent
                isOpen={editEventModalOpen}
                closeModal={closeEditEventModal}
                event={selectedEvent}
                onEventUpdated={handleUpdateEvent}
            />
            <EditShooters
                isOpen={editShootersModalOpen}
                closeModal={closeEditShootersModal}
                event={selectedEvent}
            />
            <ScoreSheetManagement
                eventId={selectedEvent?.eventId}
                isOpen={scoreSheetManagementOpen}
                onClose={closeScoreSheetManagement}
            />
        </div>
    );
};

export default DashboardScore;