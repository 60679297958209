import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../../../../contracts/ContractVars';
import './ScoreSheetManagement.css';
import { Eye, Trash2 } from 'lucide-react';


const disciplineTypes = {
    "Skeet": [
        "EnglishSkeet",
        "AmericanSkeet",
        "OlympicSkeet",
        "OlympicSkeetPre2012",
        "OlympicSkeetFinal",
        "SkeetDoubles",
        "SkeetShootOff",
        "WobbleSkeet"
    ],
    "Sporting": [
        "CompakSporting",
        "EnglishSporting",
        "FITASCSporting",
        "FiveStand",
        "Sporttrap",
        "SuperSporting"
    ],
    "Trap": [
        "AmericanTrap",
        "AmericanTrapDoubles",
        "DoubleBarrel",
        "DoubleRise",
        "DownTheLine",
        "OlympicDoubleTrap",
        "OlympicTrap",
        "SingleBarrel",
        "WobbleTrap"
    ]
};

const scoreSheetTypes = [
    { value: "Competitive", label: "Competitive" },
    { value: "FunShoot", label: "Fun Shoot" },
    { value: "Training", label: "Training" }
];

const ScoreSheetManagement = ({ eventId, isOpen, onClose }) => {
    const navigate = useNavigate();
    const [scoreSheets, setScoreSheets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [disciplineType, setDisciplineType] = useState('');
    const [variant, setVariant] = useState('');
    const [scoreSheetType, setScoreSheetType] = useState('Competitive');
    const [availableShooters, setAvailableShooters] = useState([]);
    const [selectedShooters, setSelectedShooters] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchScoreSheets = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${ApiRepos}scoresheet/event/${eventId}`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch score sheets');
            const data = await response.json();
            setScoreSheets(data);
        } catch (error) {
            console.error('Error fetching score sheets:', error);
            setError('Failed to load score sheets. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [eventId]);

    const fetchAvailableShooters = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}events/${eventId}/shooters`, {
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch shooters`);
            }

            const shootersData = await response.json();
            setAvailableShooters(shootersData);

            if (shootersData.length === 0) {
                setError('No shooters are registered for this event. Please add shooters before creating a score sheet.');
            }
        } catch (error) {
            console.error('Error fetching shooters:', error);
            setError(`Failed to load shooters. ${error.message}`);
        }
    }, [eventId]);

    useEffect(() => {
        if (isOpen && eventId) {
            fetchScoreSheets();
            fetchAvailableShooters();
        }
    }, [isOpen, eventId, fetchScoreSheets, fetchAvailableShooters]);

    const handleCreateScoreSheet = async () => {
        if (!disciplineType || !variant || selectedShooters.length === 0 || !scoreSheetType) {
            setError('Please select a discipline type, variant, score sheet type, and at least one shooter.');
            return;
        }

        setIsSubmitting(true);
        setError(null);

        try {
            const response = await fetch(`${ApiRepos}scoresheet`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    eventId,
                    disciplineType,
                    variant,
                    scoreSheetType,
                    shooterIds: selectedShooters.map(s => s.eventShooterId)
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to create score sheet');
            }

            await fetchScoreSheets();
            setDisciplineType('');
            setVariant('');
            setScoreSheetType('Competitive');
            setSelectedShooters([]);
            setError(null);
        } catch (error) {
            console.error('Error creating score sheet:', error);
            setError(error.message || 'Failed to create score sheet. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDeleteScoreSheet = async (scoreSheetId) => {
        if (!window.confirm('Are you sure you want to delete this score sheet? This action cannot be undone.')) {
            return;
        }

        try {
            const response = await fetch(`${ApiRepos}scoresheet/${scoreSheetId}`, {
                method: 'DELETE',
                credentials: 'include'
            });

            const data = await response.json();

            if (response.ok && data.success) {
                setScoreSheets(prevSheets => prevSheets.filter(sheet => sheet.scoreSheetId !== data.deletedScoreSheetId));
                setError(null);
            } else {
                setError(data.message || 'Failed to delete score sheet. Please try again.');
            }
        } catch (error) {
            console.error('Error deleting score sheet:', error);
            setError('An error occurred while deleting the score sheet. Please try again.');
        }
    };

    const handleViewScoreSheet = (scoreSheetId) => {
        navigate(`/dashboard/score/score-shoot/${scoreSheetId}`);
    };

    if (!isOpen) return null;

    return (
        <div className="create-event-container active">
            <div className="create-event-overlay" onClick={onClose}></div>
            <div className="create-event-box">
                <div className="modal-header">
                    <h2>Manage Score Sheets</h2>
                </div>
                {error && <div className="error-message">{error}</div>}

                <div className="create-event-form">
                    <h5>Create New Score Sheet</h5>

                    <div className="create-event-field-horizontal">
                        <label>Score Sheet Type:</label>
                        <select
                            value={scoreSheetType}
                            onChange={(e) => setScoreSheetType(e.target.value)}
                            className="create-event-input"
                            disabled={isSubmitting}
                        >
                            {scoreSheetTypes.map(type => (
                                <option key={type.value} value={type.value}>{type.label}</option>
                            ))}
                        </select>
                    </div>

                    <div className="create-event-field-horizontal">
                        <label>Discipline:</label>
                        <select
                            value={disciplineType}
                            onChange={(e) => {
                                setDisciplineType(e.target.value);
                                setVariant('');
                            }}
                            className="create-event-input"
                            disabled={isSubmitting}
                        >
                            <option value="">Select Discipline</option>
                            {Object.keys(disciplineTypes).map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>

                    <div className="create-event-field-horizontal">
                        <label>Variant:</label>
                        <select
                            value={variant}
                            onChange={(e) => setVariant(e.target.value)}
                            className="create-event-input"
                            disabled={!disciplineType || isSubmitting}
                        >
                            <option value="">Select Variant</option>
                            {disciplineType && disciplineTypes[disciplineType].map(v => (
                                <option key={v} value={v}>{v}</option>
                            ))}
                        </select>
                    </div>

                    <div className="create-event-field">
                        <label>Select Shooters (Selected:&nbsp;
                                 {selectedShooters.length})
                        </label>
                        <div className="shooters-select-container" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {availableShooters.map(shooter => (
                                <div key={shooter.eventShooterId} className="shooter-select-item">
                                    <div className="dashboard-form-checkbox">
                                        <input
                                            id={shooter.eventShooterId}
                                            name={shooter.eventShooterId}
                                            className='form-check-input'
                                            type="checkbox"
                                            checked={selectedShooters.some(s => s.eventShooterId === shooter.eventShooterId)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedShooters([...selectedShooters, shooter]);
                                                } else {
                                                    setSelectedShooters(selectedShooters.filter(
                                                        s => s.eventShooterId !== shooter.eventShooterId
                                                    ));
                                                }
                                            }}
                                            disabled={isSubmitting}
                                        />
                                        <label htmlFor={`${shooter.eventShooterId}`}>{shooter.appUserId ? `${shooter.firstName} ${shooter.lastName}` : shooter.shooterName}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>                    

                    <div className="create-event-btns2 mt-3">
                        <button
                            onClick={handleCreateScoreSheet}
                            className="create-event-btn-confirm create-event-btn-create"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Creating...' : 'Create Score Sheet'}
                        </button>
                    </div>
                </div>

                <div className="score-sheet-list">
                    <h5 className='mb-2'>Existing Score Sheets</h5>
                    {isLoading ? (
                        <p>Loading score sheets...</p>
                    ) : scoreSheets.length > 0 ? (
                        <p>No score sheets available for this event.</p>
                    ) : (
                        <div className='row'>
                            {scoreSheets.map(sheet => (
                                <div className="col-lg-6">
                                    <div key={sheet.scoreSheetId} className="selected-user-card">
                                        <div className="selected-user-card-heading">
                                            <h6>{sheet.scoreSheetType} - {sheet.disciplineType} - {sheet.variant}</h6>
                                            <div>Created: {new Date(sheet.createdAt).toLocaleString()}</div>
                                        </div>
                                        <div className="dashboard-card-cta mt-4">
                                            <button
                                                className="dashboard-card-outline-btn"
                                                onClick={() => handleViewScoreSheet(sheet.scoreSheetId)}
                                            >
                                                <Eye size={15} className='me-2' />
                                                View
                                            </button>
                                            <button
                                                className="dashboard-card-default-btn"
                                                onClick={() => handleDeleteScoreSheet(sheet.scoreSheetId)}
                                            >
                                                <Trash2 size={15} className='me-2' />
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    )}
                </div>
            </div>
        </div>
    );
};

export default ScoreSheetManagement;