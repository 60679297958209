import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useUser } from '../../../UserContext';
import { Plus, Trash2, AlertCircle, FilePenLine } from 'lucide-react';
import EquipmentModal from './EquipmentModal';
import './Equipment.css';

const Equipment = () => {
    const [equipment, setEquipment] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [specifications, setSpecifications] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedEquipment, setSelectedEquipment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { user } = useUser();

    const isFetchingCategories = useRef(false);
    const isFetchingEquipment = useRef(false);

    // Function to fetch specifications
    const fetchSpecifications = useCallback(async (categoryId) => {
        if (!categoryId) return;
        try {
            const response = await fetch(`${ApiRepos}equipment/specifications/${categoryId}`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch specifications');
            const data = await response.json();
            setSpecifications(data);
        } catch (err) {
            setError('Failed to load specifications');
        }
    }, []);

    // Function to fetch brands by category
    const fetchBrandsByCategory = useCallback(async (categoryId) => {
        if (!categoryId) return;
        try {
            const response = await fetch(`${ApiRepos}brands/category/${categoryId}`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch brands');
            const data = await response.json();
            setBrands(data);
        } catch (err) {
            setError('Failed to load brands');
        }
    }, []);

    const fetchCategories = useCallback(async () => {
        if (categories.length > 0 || isFetchingCategories.current) return;  // Skip fetch if already loaded
        isFetchingCategories.current = true;
        try {
            const response = await fetch(`${ApiRepos}equipment/categories`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch categories');
            const data = await response.json();
            setCategories(data);
        } catch (err) {
            setError('Failed to load equipment categories');
        } finally {
            isFetchingCategories.current = false;
        }
    }, [categories]);

    const fetchEquipment = useCallback(async () => {
        if (!user?.userId || isFetchingEquipment.current) return;  // Remove equipment.length check
        isFetchingEquipment.current = true;
        try {
            const response = await fetch(`${ApiRepos}equipment/user/${user.userId}`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch equipment');
            const data = await response.json();
            setEquipment(Array.isArray(data) ? data : []);
        } catch (err) {
            setError('Failed to load equipment');
        } finally {
            isFetchingEquipment.current = false;
        }
    }, [user?.userId]);

    useEffect(() => {
        if (!user?.userId) return;  // Only trigger if user is available

        const loadData = async () => {
            if (!isFetchingCategories.current && !isFetchingEquipment.current) {
                setLoading(true);
                try {
                    await Promise.all([fetchCategories(), fetchEquipment()]);
                } catch (err) {
                    console.error('Error loading data:', err);
                } finally {
                    setLoading(false);
                }
            }
        };

        loadData();
    }, [fetchCategories, fetchEquipment, user]);

    const handleModalClose = useCallback(() => {
        setIsAddModalOpen(false);
        setIsEditModalOpen(false);
        setSelectedEquipment(null);
        setSpecifications([]);
        setBrands([]);
    }, []);

    const showSuccessMessage = useCallback((message) => {
        setSuccessMessage(message);
        setTimeout(() => setSuccessMessage(''), 3000);
    }, []);

    const showErrorMessage = useCallback((message) => {
        setError(message);
        setTimeout(() => setError(''), 3000);
    }, []);

    const handleCategoryChange = useCallback((categoryId) => {
        if (categoryId) {
            fetchSpecifications(categoryId);
            fetchBrandsByCategory(categoryId);
        } else {
            setSpecifications([]);
            setBrands([]);
        }
    }, [fetchSpecifications, fetchBrandsByCategory]);

    const handleAddEquipment = async (formData) => {
        try {
            const response = await fetch(`${ApiRepos}equipment/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(formData)
            });

            if (!response.ok) throw new Error('Failed to add equipment');

            await fetchEquipment();
            showSuccessMessage('Equipment added successfully');
            handleModalClose();
        } catch (err) {
            showErrorMessage('Failed to add equipment');
        }
    };

    const handleEditEquipment = async (formData) => {
        try {
            const response = await fetch(`${ApiRepos}equipment/${selectedEquipment.equipmentId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    ...formData,
                    specifications: formData.specifications.map(spec => ({
                        definitionId: spec.definitionId,
                        value: spec.value
                    }))
                })
            });

            if (!response.ok) throw new Error('Failed to update equipment');

            await fetchEquipment();
            showSuccessMessage('Equipment updated successfully');
            setIsEditModalOpen(false);
        } catch (err) {
            showErrorMessage('Failed to update equipment');
        }
    };

    const handleDeleteEquipment = async (equipmentId) => {
        if (!window.confirm('Are you sure you want to delete this equipment?')) return;

        try {
            const response = await fetch(`${ApiRepos}equipment/${equipmentId}`, {
                method: 'DELETE',
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to delete equipment');

            await fetchEquipment();
            showSuccessMessage('Equipment deleted successfully');
        } catch (err) {
            showErrorMessage('Failed to delete equipment');
        }
    };    

    const renderEquipmentDetails = (item, category) => {
        const hasSpecifications = item.specifications?.length > 0;
        const hasPurchaseInfo = item.purchaseDate || item.purchasePrice;
        const hasCondition = item.condition;
        const hasSerialNumber = category.showSerialNumber && item.serialNumber;

        return (
            <div className="equipment-card-content">
                {hasSpecifications && (
                    <div className="specifications-section">
                        {item.specifications.map(spec => (
                            <div key={spec.specificationId} className="specifications-item">
                                <h6>{spec.name}:</h6>
                                <span>
                                    {spec.value} {spec.unit}
                                </span>
                            </div>
                        ))}
                    </div>
                )}

                {hasSerialNumber && (
                    <div className="specifications-item">
                        <h6>Serial Number:</h6>
                        <span>{item.serialNumber}</span>
                    </div>
                )}

                {/* {item.description && (
                    <div className="description-section">
                        <p>{item.description}</p>
                    </div>
                )} */}

                {(hasPurchaseInfo || hasCondition) && (
                    <>
                        {item.purchaseDate && (
                            <div className="specifications-item">
                                <h6>Purchased:</h6>
                                <span>{new Date(item.purchaseDate).toLocaleDateString()}</span>
                            </div>
                        )}
                        {item.purchasePrice && (
                            <div className="specifications-item">
                                <h6>Price:</h6>
                                <span>{item.purchasePrice.toFixed(2)}</span>
                            </div>
                        )}
                        {hasCondition && (
                            <div className="specifications-item">
                                <h6>Condition:</h6>
                                <span>{item.condition}</span>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    const renderEquipmentCard = (item, category) => (
        <div key={item.equipmentId} className="col-lg-4 col-md-6 dashboard-card-container">
            <div className="dashboard-card">
                <h3 className="dashboard-card-headline">{item.brandName}</h3>
                <h6 className="dashboard-card-describer">{category.showModel && item.model && ` ${item.model}`}</h6>

                {renderEquipmentDetails(item, category)}

                <div className="dashboard-card-cta mt-4">
                    <button
                        className="dashboard-card-outline-btn"
                        onClick={() => {
                            setSelectedEquipment(item);
                            setIsEditModalOpen(true);
                        }}
                    >
                        <FilePenLine size={15} className='me-2' />
                        Edit
                    </button>
                    <button
                        className="dashboard-card-default-btn"
                        onClick={() => handleDeleteEquipment(item.equipmentId)}
                    >
                        <Trash2 size={15} className='me-2' />
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );

    const renderCategories = useCallback(() => {
        const equipmentMap = new Map(
            equipment.map(item => [item.categoryId, true])
        );

        return categories
            .filter(c => c.isActive)
            .sort((a, b) => {
                const aHasEquipment = equipmentMap.has(a.categoryId);
                const bHasEquipment = equipmentMap.has(b.categoryId);

                if (aHasEquipment !== bHasEquipment) {
                    return aHasEquipment ? -1 : 1;
                }
                if (a.categoryId === 1) return -1;
                if (b.categoryId === 1) return 1;

                return a.name.localeCompare(b.name);
            });
    }, [categories, equipment]);

    if (loading) {
        return <div className="loading">Loading equipment data...</div>;
    }

    return (

        <div className="dashboard-component">
            <div className="dashboard-heading">
                <h1>Equipment</h1>
                <h2>Manage your equipment and use it within scoring etc. to hone in your skills with the right gear.</h2>
            </div>
            <div className="d-flex justify-content-end">
                <button className="dashboard-default-btn" onClick={() => setIsAddModalOpen(true)}>
                    <Plus size={20} className='me-2' />
                    <span>Add Equipment</span>
                </button>
            </div>
            {equipment.length === 0 ? (
                <div className="no-equipment mt-4">
                    <p>You haven't added any equipment yet.</p>
                </div>
            ) : (
                renderCategories().map(category => {
                    const categoryEquipment = equipment.filter(item => item.categoryId === category.categoryId);
                    if (categoryEquipment.length === 0) return null;

                    return (
                        <div key={category.categoryId}>
                            <h4 className='dashboard-component-title mt-4'>{category.name}</h4>
                            <div className="row">
                                {categoryEquipment.map(item => renderEquipmentCard(item, category))}
                            </div>
                        </div>
                    );
                })
            )}

            {error && (
                <div className="error-toast">
                    <AlertCircle size={20} />
                    <span>{error}</span>
                </div>
            )}

            {successMessage && (
                <div className="success-toast">
                    <span>{successMessage}</span>
                </div>
            )}

            {isAddModalOpen && (
                <EquipmentModal
                    categories={categories}
                    brands={brands}
                    onClose={handleModalClose}
                    onSubmit={handleAddEquipment}
                    onCategoryChange={handleCategoryChange}
                    specifications={specifications}
                />
            )}

            {isEditModalOpen && selectedEquipment && (
                <EquipmentModal
                    categories={categories}
                    brands={brands}
                    onClose={handleModalClose}
                    onSubmit={handleEditEquipment}
                    onCategoryChange={handleCategoryChange}
                    specifications={specifications}
                    equipment={selectedEquipment}
                    isEdit
                />
            )}
        </div>
    );
};

export default Equipment;
