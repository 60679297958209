import React, { useState, useEffect } from 'react';
import './DashboardHeader.css'
import settings from '../../assets/icons/dashboard-header-settings.svg'
import defaultProfile from '../../assets/images/dashboard-header-profile.jpg'
import { Link, useNavigate } from 'react-router-dom'
import { ApiRepos } from '../../contracts/ContractVars';
import { useUser } from '../../UserContext';
import { ArrowUpRight } from 'lucide-react';

const DashboardHeader = ({ toggleSidebar, opened }) => {
    const { user, setUser } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [greeting, setGreeting] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${ApiRepos}account/current-user`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setUser(data);
                setIsLoading(false);
            } catch (err) {
                setError('Failed to fetch user data. Please try logging in again.');
                setIsLoading(false);
                if (err.message.includes('401')) {
                    navigate('/', { state: { message: 'Your session has expired. Please log in again.' } });
                }
            }
        };
        fetchUserData();

        const hour = new Date().getHours();
        if (hour >= 5 && hour < 12) {
            setGreeting('Good Morning');
        } else if (hour >= 12 && hour < 18) {
            setGreeting('Good Afternoon');
        } else {
            setGreeting('Good Evening');
        }
    }, [navigate, setUser]);

    const getSubscriptionContent = () => {
        const status = user?.subscription?.status || 'Free';

        return (
            <>
                <span style={{ color: '#353231' }}>{greeting}, </span>
                <span className='header-username'>{user.firstName}</span>
                <span className='header-status'>
                    {status}
                </span>
                {status === 'Free' && (
                    <Link to="/dashboard/subscription" className='header-upgrade-cta'>
                        Upgrade to VIP
                        <ArrowUpRight />
                    </Link>
                )}
            </>
        );
    };

    if (isLoading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">{error}</div>;
    if (!user) return <div className="error">No user data available.</div>;

    const profilePicture = user.profilePictureUrl || defaultProfile;

    return (
        <div className='dashboard-header'>
            <div className="dashboard-header-left">
                <h2>
                    {getSubscriptionContent()}
                </h2>
            </div>
            <div className="dashboard-header-right">
                <Link to={'/dashboard/setting'} className="dashboard-header-icon">
                    <img src={settings} alt="Settings" />
                </Link>
                <Link to={'#'} className="dashboard-header-profile">
                    <img src={profilePicture} alt="Profile" />
                </Link>
                <div
                    className={`sidebar-toggler ${opened && 'active'}`}
                    onClick={toggleSidebar}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )
}

export default DashboardHeader