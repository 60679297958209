import React, { useState, useEffect, useCallback } from 'react';
import './Settings.css';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useUser } from '../../../UserContext';  // Adjust the import path as needed
import avatar from '../../../assets/images/profile.png'
import { FilePenLine } from 'lucide-react';

const Settings = () => {
    const { user, setUser } = useUser();
    const [profileImage, setProfileImage] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Notification preferences
    const [notifyByEmail, setNotifyByEmail] = useState(true);
    const [notifyBySMS, setNotifyBySMS] = useState(false);
    const [notifyByCall, setNotifyByCall] = useState(false);

    // Sharing preferences
    const [shareShootingScores, setShareShootingScores] = useState(false);
    const [shareLastShoots, setShareLastShoots] = useState(false);
    const [shareClubMemberships, setShareClubMemberships] = useState(false);
    const [shareEquipmentDetails, setShareEquipmentDetails] = useState(false);

    const [errors, setErrors] = useState({});

    const fetchUserProfile = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}user/profile`, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch user profile');
            const userData = await response.json();
            setUser(userData);
            setFirstName(userData.firstName);
            setLastName(userData.lastName);
            setEmail(userData.email);
            setUserName(userData.userName);
            setMobileNumber(userData.mobileNumber);
            setDateOfBirth(userData.dateOfBirth ? new Date(userData.dateOfBirth).toISOString().split('T')[0] : '');
            setProfileImage(userData.profilePictureUrl);

            // Set notification preferences
            setNotifyByEmail(userData.notifyByEmail);
            setNotifyBySMS(userData.notifyBySMS);
            setNotifyByCall(userData.notifyByCall);

            // Set sharing preferences
            setShareShootingScores(userData.shareShootingScores);
            setShareLastShoots(userData.shareLastShoots);
            setShareClubMemberships(userData.shareClubMemberships);
            setShareEquipmentDetails(userData.shareEquipmentDetails);
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    }, [setUser]);

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
            setUserName(user.userName);
            setMobileNumber(user.mobileNumber);
            setDateOfBirth(user.dateOfBirth ? new Date(user.dateOfBirth).toISOString().split('T')[0] : '');
            setProfileImage(user.profilePictureUrl);
            setNotifyByEmail(user.notifyByEmail);
            setNotifyBySMS(user.notifyBySMS);
            setNotifyByCall(user.notifyByCall);
            setShareShootingScores(user.shareShootingScores);
            setShareLastShoots(user.shareLastShoots);
            setShareClubMemberships(user.shareClubMemberships);
            setShareEquipmentDetails(user.shareEquipmentDetails);
        } else {
            fetchUserProfile();
        }
    }, [user, fetchUserProfile]);

    const handleImageUpload = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setProfileImage(URL.createObjectURL(selectedFile));
    };

    const handleSave = async () => {
        setIsLoading(true);
        setErrors({});
        try {
            const updatedProfile = {
                firstName,
                lastName,
                userName,
                email,
                mobileNumber,
                dateOfBirth: dateOfBirth ? new Date(dateOfBirth).toISOString() : null,
                notifyByEmail,
                notifyBySMS,
                notifyByCall,
                shareShootingScores,
                shareLastShoots,
                shareClubMemberships,
                shareEquipmentDetails
            };

            const profileResponse = await fetch(`${ApiRepos}user/updateProfile`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(updatedProfile)
            });

            if (!profileResponse.ok) {
                const errorData = await profileResponse.json();
                if (errorData.errors) {
                    setErrors(errorData.errors);
                } else if (errorData.error) {
                    setErrors({ general: errorData.error });
                } else {
                    throw new Error('Failed to update profile');
                }
                return;
            }

            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                const imageResponse = await fetch(`${ApiRepos}user/upload-profile-picture`, {
                    method: 'POST',
                    credentials: 'include',
                    body: formData
                });
                if (!imageResponse.ok) throw new Error('Failed to upload image');
                const imageData = await imageResponse.json();
                setProfileImage(imageData.url);
                updatedProfile.profilePictureUrl = imageData.url;
            }

            setUser(prevUser => ({
                ...prevUser,
                ...updatedProfile
            }));

            alert("Profile updated successfully!");
        } catch (error) {
            console.error('Error updating profile:', error);
            setErrors({ general: "Failed to update profile. Please try again." });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='dashboard-component'>
            <div className="dashboard-heading">
                <h1>Profile</h1>
                <h2>Update your profile and settings to ensure you and your friends always get up to date news & notifications</h2>
            </div>
            <div className="row">
                <div className="col-lg-3 py-3">
                    <div className="profile-preview">
                        <img 
                            src={profileImage || avatar} 
                            alt="Profile" 
                            className='profile-preview-img' 
                        />
                        <h5 className="profile-preview-name">{firstName} {lastName}</h5>
                        <h6 className='profile-preview-username'>@{userName}</h6>
                        <div className="profile-preview-info">
                            <h6><a href={`mailto:${email}`}>{email}</a></h6>
                            <h6><a href={`tel:${mobileNumber}`}>{mobileNumber}</a></h6>
                            <h6>{dateOfBirth}</h6>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 py-3">
                    {errors.general && <div className="error-message">{errors.general}</div>}
                    <div className="profile-section">
                        <div className="profile-image">
                            <img src={profileImage || avatar} alt="Profile" />
                            <span className='profile-img-btn'><FilePenLine size={15} /></span>
                            <input type="file" onChange={handleImageUpload} accept="image/*" />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>First Name</label>
                                <div className="dashboard-form-field">
                                    <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='First Name' />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Last Name</label>
                                <div className="dashboard-form-field">
                                    <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Last Name' />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Username</label>
                                <div className="dashboard-form-field">
                                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder='username' />
                                </div>
                                {errors.userName && <div className="error-message">{errors.userName}</div>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Email</label>
                                <div className="dashboard-form-field">
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                                </div>
                                {errors.email && <div className="error-message">{errors.email}</div>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Mobile Number</label>
                                <div className="dashboard-form-field">
                                    <input type="tel" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder='Mobile Number' />
                                </div>
                                {errors.mobileNumber && <div className="error-message">{errors.mobileNumber}</div>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Date of Birth</label>
                                <div className="dashboard-form-field">
                                    <input type="date" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>License Number</label>
                                <div className="dashboard-form-field">
                                    <input type="number" placeholder='License Number' />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Expiry Date</label>
                                <div className="dashboard-form-field">
                                    <input type="date" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h6 className='mt-3 mb-2'>Notification Preferences</h6>
                    <div className="checboxes">
                        <div className="dashboard-form-checkbox">
                            <input
                                type="checkbox"
                                id='email'
                                checked={notifyByEmail}
                                onChange={(e) => setNotifyByEmail(e.target.checked)}
                                className='form-check-input'
                            />
                            <label htmlFor='email'>Email</label>
                        </div>
                        <div className="dashboard-form-checkbox">
                            <input
                                id='sms'
                                type="checkbox"
                                checked={notifyBySMS}
                                onChange={(e) => setNotifyBySMS(e.target.checked)}
                                className='form-check-input'
                            />
                            <label htmlFor='sms'>SMS</label>
                        </div>
                        <div className="dashboard-form-checkbox">
                            <input
                                id='phoneCall'
                                type="checkbox"
                                checked={notifyByCall}
                                onChange={(e) => setNotifyByCall(e.target.checked)}
                                className='form-check-input'
                            />
                            <label htmlFor='phoneCall'>Phone call</label>
                        </div>
                    </div>
                    <h6 className='mt-4 mb-2'>Sharing Preferences</h6>
                    <div className="checboxes">
                        <div className="dashboard-form-checkbox">
                            <input
                                id='shootingScores'
                                type="checkbox"
                                checked={shareShootingScores}
                                onChange={(e) => setShareShootingScores(e.target.checked)}
                                className='form-check-input'
                            />
                            <label htmlFor='shootingScores'>Shooting scores</label>
                        </div>
                        <div className="dashboard-form-checkbox">
                            <input
                                id='lastShoots'
                                type="checkbox"
                                checked={shareLastShoots}
                                onChange={(e) => setShareLastShoots(e.target.checked)}
                                className='form-check-input'
                            />
                            <label htmlFor='lastShoots'>Last shoots</label>
                        </div>
                        <div className="dashboard-form-checkbox">
                            <input
                                id='clubMemberships'
                                type="checkbox"
                                checked={shareClubMemberships}
                                onChange={(e) => setShareClubMemberships(e.target.checked)}
                                className='form-check-input'
                            />
                            <label htmlFor='clubMemberships'>Club Memberships</label>
                        </div>
                        <div className="dashboard-form-checkbox">
                            <input
                                id='equipmentDetails'
                                type="checkbox"
                                checked={shareEquipmentDetails}
                                onChange={(e) => setShareEquipmentDetails(e.target.checked)}
                                className='form-check-input'
                            />
                            <label htmlFor='equipmentDetails'>Equipment Details</label>
                        </div>
                    </div>
                    <h6 className='mt-4 mb-2'>License</h6>
                    <div className="checboxes">
                        <div className="dashboard-form-checkbox">
                            <input
                                id='renewalReminders'
                                type="checkbox"
                                className='form-check-input'
                            />
                            <label htmlFor='renewalReminders'>Renewal reminders</label>
                        </div>
                    </div>
                    <div className="w-100 mt-4 d-flex justify-content-end">
                        <button className="dashboard-default-btn" onClick={handleSave} disabled={isLoading}>
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;