import React from 'react'

const EquipmentAndGear = () => {
  return (
    <div className='dashboard-card h-100'>

        <div className="dashboard-card-top">
            <h3>Equipment & Gear</h3>
        </div>
        
    </div>
  )
}

export default EquipmentAndGear