import React, { useState, useEffect } from 'react';
import './DashboardHome.css';
import HomeWidget from './components/HomeWidget';
import ScoreWidget from './components/ScoreWidget';
import UpcomingEvents from './components/UpcomingEvents';
import HomeLeaderboard from './components/HomeLeaderboard';
import HomePerformance from './components/HomePerformance';
import HomeActivities from './components/HomeActivities';
import GoalsAndProgress from './components/GoalsAndProgress';
import HomeAchievements from './components/HomeAchievements';
import EquipmentAndGear from './components/EquipmentAndGear';
import HomeFriends from './components/HomeFriends';
import HomeMembership from './components/HomeMembership';

const DashboardHome = ({ userData }) => {
    const [recentScores, setRecentScores] = useState([]);
    const [clubActivity, setClubActivity] = useState([]);

    useEffect(() => {
        // Fetch recent scores and club activity data
        fetchRecentScores();
        fetchClubActivity();
    }, []);

    const fetchRecentScores = async () => {
        // Implement API call to fetch recent scores
        const mockData = [
            { date: '2024-03-01', score: 85 },
            { date: '2024-03-15', score: 88 },
            { date: '2024-04-01', score: 92 },
        ];
        setRecentScores(mockData);
    };

    const fetchClubActivity = async () => {
        // Implement API call to fetch club activity
        const mockData = [
            { id: 1, club: 'Acme Shooting Club', activity: 'New equipment arrived' },
            { id: 2, club: 'Downtown Shooters', activity: 'Upcoming tournament announced' },
        ];
        setClubActivity(mockData);
    };

    return (
        <div className="dashboard-component">
            <div className='row'>
                <div className="col-xl-6 m-cards">
                    <div className="row">
                        <div className="col-xl-6 col-md-4 dashboard-card-container">
                            <HomeWidget time={'This week'} percentage={55} number={557} />
                        </div>
                        <div className="col-xl-6 col-md-4 dashboard-card-container">
                            <HomeWidget time={'Month'} percentage={30} number={15000} />
                        </div>
                        <div className="col-md-4 d-xl-none dashboard-card-container">
                            <HomeWidget time={'Year'} percentage={80} number={245830} />
                        </div>
                    </div>
                    <div className="row f-height">
                        <div className="col-12 dashboard-card-container">
                            <div className='dashboard-card'>
                                <h3 className='dashboard-card-title'>Recent Scores</h3>
                                <div className="recent-scores-list">
                                    {recentScores.map((score, index) => (
                                        <div key={index} className="recent-score-item">
                                            <span className="recent-score-date">{score.date}</span>
                                            <span className="recent-score-value">{score.score}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="row">
                        <div className="col-md-6 m-cards px-0">
                            <div className="dashboard-card-container d-none d-xl-block">
                                <HomeWidget time={'Year'} percentage={80} number={245830} />
                            </div>
                            <div className="dashboard-card-container f-height">
                                <ScoreWidget />
                            </div>
                        </div>
                        <div className="col-md-6 px-0">
                            <div className="dashboard-card-container">
                                <UpcomingEvents />
                            </div>
                            <div className="dashboard-card-container">
                                <HomePerformance />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 dashboard-card-container">
                    <HomeLeaderboard />
                </div>
                <div className="col-lg-6 dashboard-card-container">
                    <HomeActivities />
                </div>
                <div className="col-lg-6 px-0">
                    <div className="dashboard-card-container">
                        <GoalsAndProgress />
                    </div>
                    <div className="dashboard-card-container">
                        <HomeAchievements />
                    </div>
                </div>
                <div className="col-lg-6 m-cards px-0">
                    <div className="dashboard-card-container f-height">
                        <EquipmentAndGear />
                    </div>
                    <div className="bottom-cards">
                        <div className="dashboard-card-container">
                            <HomeFriends />
                        </div>
                        <div className="dashboard-card-container">
                            <HomeMembership />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 dashboard-card-container">
                    <div className='dashboard-card'>
                        <h3 className='dashboard-card-title'>Club Activity</h3>
                        <ul className="club-activity-list">
                            {clubActivity.map(activity => (
                                <li key={activity.id} className="club-activity-item">
                                    <strong>{activity.club}</strong>: {activity.activity}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardHome;