import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useUser } from '../../../UserContext';
import { MoveRight } from 'lucide-react';
import './Friends.css';
import { Link } from 'react-router-dom';

const Friends = () => {

    const [tab, setTab] = useState(1)
    const [friends, setFriends] = useState([]);
    const [incomingFriendRequests, setIncomingFriendRequests] = useState([]);
    const [outgoingFriendRequests, setOutgoingFriendRequests] = useState([]);
    const [newFriendIdentifier, setNewFriendIdentifier] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const { user } = useUser(); // Assume user is already authenticated

    // Get userId from user object
    const userId = useMemo(() => user?.userId || user?.id, [user]);

    // Clear messages after 5 seconds
    useEffect(() => {
        if (error || successMessage) {
            const timer = setTimeout(() => {
                setError('');
                setSuccessMessage('');
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [error, successMessage]);

    const fetchFriends = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}friends/view`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId }),
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to fetch friends');

            const data = await response.json();
            setFriends(Array.isArray(data) ? data : []);
        } catch (err) {
            console.error('Error fetching friends:', err);
            setError('Failed to load friends. Please try again later.');
        }
    }, [userId]);

    const fetchFriendRequests = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}friends/requests`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId }),
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Failed to fetch friend requests');

            const data = await response.json();
            setIncomingFriendRequests(data.incomingRequests || []);
            setOutgoingFriendRequests(data.outgoingRequests || []);
        } catch (err) {
            console.error('Error fetching friend requests:', err);
            setError('Failed to load friend requests. Please try again later.');
        }
    }, [userId]);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                await Promise.all([fetchFriends(), fetchFriendRequests()]);
            } catch (err) {
                setError('Failed to load friends data.');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [fetchFriends, fetchFriendRequests]);


    const addFriend = async (e) => {
        e.preventDefault();
        if (!userId || !newFriendIdentifier.trim()) return;

        setError('');
        setSuccessMessage('');

        try {
            const response = await fetch(`${ApiRepos}friends/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    requesterId: userId,
                    receiverIdentifier: newFriendIdentifier.trim()
                }),
                credentials: 'include'
            });

            const data = await response.json();

            if (!response.ok) {
                if (data.error) {
                    setError(data.error);
                } else {
                    setError('Failed to send friend request. Please try again.');
                }
                return;
            }

            setSuccessMessage(data.message);
            setNewFriendIdentifier('');
            await fetchFriendRequests();
        } catch (err) {
            console.error('Error sending friend request:', err);
            setError('Failed to connect to server. Please try again.');
        }
    };

    const handleFriendAction = async (actionType, friendId) => {
        setError('');
        setSuccessMessage('');

        const actions = {
            remove: { endpoint: 'remove', successMsg: 'Friend removed successfully!' },
            accept: { endpoint: 'accept', successMsg: 'Friend request accepted successfully!' },
            reject: { endpoint: 'reject', successMsg: 'Friend request rejected successfully!' },
            cancel: { endpoint: 'cancel', successMsg: 'Friend request cancelled successfully!' }
        };

        const action = actions[actionType];
        if (!action) return;

        try {
            const response = await fetch(`${ApiRepos}friends/${action.endpoint}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ FriendID: friendId }),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`Failed to ${actionType} friend request`);
            }

            setSuccessMessage(action.successMsg);

            // Refresh appropriate lists based on action
            if (actionType === 'remove') {
                await fetchFriends();
            } else {
                await Promise.all([fetchFriends(), fetchFriendRequests()]);
            }
        } catch (err) {
            console.error(`Error ${actionType}ing friend request:`, err);
            setError(`Failed to ${actionType} friend request. Please try again.`);
        }
    };

    if (loading) {
        return <div className="loading">Loading friends data...</div>;
    }

    return (
        <div className="dashboard-component">
            <div className="dashboard-heading">
                <h1>Friends</h1>
                <h2>Add friends to make scoring & competing together fun and easy.</h2>
            </div>
            {successMessage && <div className="success-message">{successMessage}</div>}
            {error && <div className="error-message input-error">{error}</div>}
            <form onSubmit={addFriend}>
                <div className="dashboard-search-container">
                    <div className="dashboard-search-field">
                        <input 
                            type="text"
                            value={newFriendIdentifier}
                            onChange={(e) => setNewFriendIdentifier(e.target.value)}
                            placeholder="Enter friend's email or username"
                            required
                        />
                    </div>
                    <button 
                        type='submit'
                        className='dashboard-search-submit'
                        disabled={!newFriendIdentifier.trim()}
                    >Send Request</button>
                </div>
            </form>
            <div className="dashboard-tabs">
                <button className={`dashboard-tab ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>Invites</button>
                <button className={`dashboard-tab ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>Your Friends</button>
            </div>
            {
                tab === 1 && (
                    <>
                        <h4 className='dashboard-component-title'>Incoming</h4>
                        {incomingFriendRequests.length === 0 ? (
                            <p className="no-data">No incoming friend requests.</p>
                        ) : (
                            <div className="row">
                                {incomingFriendRequests.map((request) => (
                                    <div key={request.id} className="col-lg-4 col-md-6 dashboard-card-container">
                                        <div className="dashboard-card">
                                            <div className="friend-card-top">
                                                <div className="friend-card-top-left">
                                                    <img src={request.imageUrl} alt={request.requesterName} />
                                                    <h6>{request.requesterName}</h6>
                                                </div>
                                                <Link to={`/dashboard/friends/${request.id}`} className='friend-card-top-right'>
                                                    <span>View Profile</span>
                                                    <MoveRight size={15} />
                                                </Link>
                                            </div>
                                            <div className="friend-card-info">
                                                <h6>Last Shot With</h6>
                                                <span>{request.lastShotWith}</span>
                                            </div>
                                            <div className="friend-card-info">
                                                <h6>Last Event Date</h6>
                                                <span>{request.lastEventDate}</span>
                                            </div>
                                            <div className="friend-card-info">
                                                <h6>Accuracy Percentage</h6>
                                                <span>{request.accuracyPercentage}</span>
                                            </div>
                                            <div className="dashboard-card-cta mt-4">
                                                <button
                                                    className="dashboard-card-outline-btn"
                                                    onClick={() => handleFriendAction('reject', request.id)}
                                                >
                                                    Decline
                                                </button>
                                                <button
                                                    className="dashboard-card-default-btn"
                                                    onClick={() => handleFriendAction('accept', request.id)}
                                                >
                                                    Accept
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <h4 className='dashboard-component-title mt-4'>Outgoing</h4>
                        {outgoingFriendRequests.length === 0 ? (
                            <p className="no-data">No outgoing friend requests.</p>
                        ) : (
                            <div className="row">
                                {outgoingFriendRequests.map((request) => (
                                    <div key={request.id} className="col-lg-4 col-md-6 dashboard-card-container">
                                        <div className="dashboard-card">
                                            <div className="friend-card-top">
                                                <div className="friend-card-top-left">
                                                    <img src={request.imageUrl} alt={request.receiverName} />
                                                    <h6>{request.receiverName}</h6>
                                                </div>
                                                <Link to={`/dashboard/friends/${request.id}`} className='friend-card-top-right'>
                                                    <span>View Profile</span>
                                                    <MoveRight size={15} />
                                                </Link>
                                            </div>
                                            <div className="friend-card-info">
                                                <h6>Last Shot With</h6>
                                                <span>{request.lastShotWith}</span>
                                            </div>
                                            <div className="friend-card-info">
                                                <h6>Last Event Date</h6>
                                                <span>{request.lastEventDate}</span>
                                            </div>
                                            <div className="friend-card-info">
                                                <h6>Accuracy Percentage</h6>
                                                <span>{request.accuracyPercentage}</span>
                                            </div>
                                            <div className="dashboard-card-cta mt-4">
                                                <button
                                                    className="dashboard-card-default-btn"
                                                    onClick={() => handleFriendAction('cancel', request.id)}
                                                >
                                                    Cancel Request
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )
            }
            {
                tab === 2 && (
                    <>
                        {friends.length === 0 ? (
                            <p className="no-data">You don't have any friends yet.</p>
                        ) : (
                            <div className="row">
                                {friends.map((friend) => (
                                    <div key={friend.id} className="col-lg-4 col-md-6 dashboard-card-container">
                                        <div className="dashboard-card">
                                            <div className="friend-card-top">
                                                <div className="friend-card-top-left">
                                                    <img src={friend.imageUrl} alt={friend.friendName} />
                                                    <h6>{friend.friendName}</h6>
                                                </div>
                                                <Link to={`/dashboard/friends/${friend.id}`} className='friend-card-top-right'>
                                                    <span>View Profile</span>
                                                    <MoveRight size={15} />
                                                </Link>
                                            </div>
                                            <div className="friend-card-info">
                                                <h6>Last Shot With</h6>
                                                <span>{friend.lastShotWith}</span>
                                            </div>
                                            <div className="friend-card-info">
                                                <h6>Last Event Date</h6>
                                                <span>{friend.lastEventDate}</span>
                                            </div>
                                            <div className="friend-card-info">
                                                <h6>Accuracy Percentage</h6>
                                                <span>{friend.accuracyPercentage}</span>
                                            </div>
                                            <div className="dashboard-card-cta mt-4">
                                                <button
                                                    className="dashboard-card-default-btn"
                                                    onClick={() => handleFriendAction('remove', friend.id)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )
            }        
        </div>
    );
};

export default Friends;
