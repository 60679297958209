import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import logo from '../../assets/images/logo.svg';
import home from '../../assets/icons/sidebar-home.svg';
import clubs from '../../assets/icons/sidebar-clubs.svg';
import score from '../../assets/icons/sidebar-score.svg';
import friends from '../../assets/icons/sidebar-friends.svg';
import download from '../../assets/icons/sidebar-download.svg';
import profile from '../../assets/icons/sidebar-profile.svg';
import subscription from '../../assets/icons/sidebar-subscription.png';
import equipment from '../../assets/icons/sidebar-shotgun.png';
import { ApiRepos } from '../../contracts/ContractVars';
import { LogOut } from 'lucide-react';

const Sidebar = ({ toggleSidebar, opened }) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const response = await fetch(`${ApiRepos}account/logout`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                navigate('/');
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const sidebarItems = [
        { title: 'Dashboard', icon: home, path: 'home' },
        { title: 'Clubs', icon: clubs, path: 'clubs' },
        { title: 'Score Events', icon: score, path: 'score' },
        { title: 'Friends', icon: friends, path: 'friends' },
        { title: 'Equipment',icon: equipment, path: 'equipment'},
        { title: 'Download', icon: download, path: 'download' },
        { title: 'Profile', icon: profile, path: 'setting' },
        { title: 'Subscription', icon: subscription, path: 'subscription' },
    ];

    return (
        <>
            <div className={`sidebar-container ${opened ? 'active' : ''}`}>
                <div className="sidebar">
                    <div className="sidebar-logo">
                        <Link to={'/dashboard/home'}>
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <div className="sidebar-divider"></div>
                    <ul className='sidebar-links'>
                        {sidebarItems.map((item, i) => (
                            <li key={i}>
                                <NavLink
                                    to={item.path}
                                    className={({ isActive }) => isActive ? 'active sidebar-link' : 'sidebar-link'}
                                    onClick={toggleSidebar}
                                >
                                    <div className="sidebar-link-icon">
                                        <img src={item.icon} alt={item.title} />
                                    </div>
                                    {item.title}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <button onClick={handleLogout} className="dashboard-default-btn w-100">
                        <LogOut size={15} className='me-2' />
                        Logout
                    </button>
                </div>
            </div>
            <div
                className={`sidebar-overlay ${opened ? 'active' : ''}`}
                onClick={toggleSidebar}
            ></div>
        </>
    );
};

export default Sidebar;