import React, { createContext, useState, useContext, useCallback } from 'react';
import { ApiRepos } from './contracts/ContractVars';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const normalizeUserData = (userData) => {
        if (!userData) return null;

        const subscription = userData.subscription ?? {
            id: 0,
            status: 'Free', // Changed default status to "Free"
            startDate: new Date().toISOString(),
            endDate: null
        };

        return {
            userId: userData.userId ?? userData.id,
            userName: userData.userName ?? userData.username,
            email: userData.email,
            mobileNumber: userData.mobileNumber,
            firstName: userData.firstName,
            lastName: userData.lastName,
            verified: userData.verified,
            profilePictureUrl: userData.profilePictureUrl,
            dateOfBirth: userData.dateOfBirth,
            notifyByEmail: userData.notifyByEmail ?? false,
            notifyBySMS: userData.notifyBySMS ?? false,
            notifyByCall: userData.notifyByCall ?? false,
            shareShootingScores: userData.shareShootingScores ?? false,
            shareLastShoots: userData.shareLastShoots ?? false,
            shareClubMemberships: userData.shareClubMemberships ?? false,
            shareEquipmentDetails: userData.shareEquipmentDetails ?? false,
            subscription: subscription
        };
    };

    const updateUser = useCallback((userData) => {
        const normalizedUser = normalizeUserData(userData);
        setUser(normalizedUser);
        setInitialized(true);
    }, []);

    const fetchUserProfile = useCallback(async () => {
        if (loading) return user;

        setLoading(true);
        try {
            const response = await fetch(`${ApiRepos}account/current-user`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 401) {
                setUser(null);
                setInitialized(true);
                return null;
            }

            if (!response.ok) {
                throw new Error('Failed to fetch user profile');
            }

            const userData = await response.json();
            const normalizedUser = normalizeUserData(userData);
            setUser(normalizedUser);
            setInitialized(true);
            return normalizedUser;
        } catch (error) {
            console.error('Error fetching user profile:', error);
            setUser(null);
            setInitialized(true);
            return null;
        } finally {
            setLoading(false);
        }
    }, [loading, user]);

    const logout = useCallback(async () => {
        try {
            await fetch(`${ApiRepos}account/logout`, {
                method: 'GET',
                credentials: 'include',
            });
        } finally {
            setUser(null);
            setInitialized(false);
        }
    }, []);

    return (
        <UserContext.Provider value={{
            user,
            setUser: updateUser,
            fetchUserProfile,
            logout,
            isAuthenticated: !!user?.userId,
            loading,
            initialized
        }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
