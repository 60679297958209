import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Subscription.css';

const SubscriptionCancel = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/dashboard/subscription');
        }, 2000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="subscription-page">
            <h2>Subscription Cancelled</h2>
            <div className="current-subscription">
                <h3>Your subscription process was cancelled.</h3>
                <p>No changes have been made to your account.</p>
                <p>You will be redirected to the subscription page shortly.</p>
            </div>
            <div className="subscription-tiers">
                <h3>Still Interested?</h3>
                <div className="tier-container">
                    <div className="tier">
                        <h4>Benefits of VIP/Premium</h4>
                        <ul>
                            <li>Full functionality</li>
                            <li>Unlimited access</li>
                            <li>Unlimited projects</li>
                            <li>Priority support</li>
                            <li>Advanced analytics</li>
                        </ul>
                        <p>You can upgrade anytime from your subscription page.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionCancel;