import React, { useState, useEffect, useCallback } from 'react';
import { ApiRepos } from '../../../../contracts/ContractVars';
import ShooterSelector from './ShooterSelector';
import './EditShooters.css';
import { Trash2 } from 'lucide-react';

const EditShooters = ({ isOpen, closeModal, event }) => {
    
    const [selectedShooters, setSelectedShooters] = useState([]);
    const [error, setError] = useState(null);

    const fetchEventShooters = useCallback(async () => {
        if (!event) return;
        try {
            const response = await fetch(`${ApiRepos}events/${event.eventId}/shooters`, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch event shooters');
            const data = await response.json();
            setSelectedShooters(data || []);
        } catch (error) {
            console.error('Error fetching event shooters:', error);
            setError('Failed to load event shooters. Please try again.');
        }
    }, [event]);

    useEffect(() => {
        if (isOpen && event) {
            setSelectedShooters([]);
            setError(null);
            fetchEventShooters();
        }
    }, [isOpen, event, fetchEventShooters]);

    const addShooter = async (shooter) => {
        if (selectedShooters.some(s =>
            (shooter.appUserId && s.appUserId === shooter.appUserId) ||
            (shooter.shooterName && s.shooterName === shooter.shooterName))) {
            setError('This shooter is already added to the event.');
            return;
        }

        try {
            const response = await fetch(`${ApiRepos}events/${event.eventId}/shooters`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    appUserId: shooter.appUserId,
                    shooterName: shooter.shooterName
                })
            });
            if (!response.ok) throw new Error('Failed to add shooter');
            await fetchEventShooters();
            setError(null);
        } catch (error) {
            console.error('Error adding shooter:', error);
            setError('Failed to add shooter. Please try again.');
        }
    };

    const removeShooter = async (shooter) => {
        try {
            const response = await fetch(`${ApiRepos}events/${event.eventId}/shooters/${shooter.eventShooterId}`, {
                method: 'DELETE',
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to remove shooter');
            await fetchEventShooters();
            setError(null);
        } catch (error) {
            console.error('Error removing shooter:', error);
            setError('Failed to remove shooter. Please try again.');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="create-event-box" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Edit shooters for {event.name}</h2>
                </div>

                {error && <div className="error-message">{error}</div>}

                <div className="shooter-selector-container">
                    <ShooterSelector onShooterSelect={addShooter} />
                </div>

                <div className="current-shooters-section">
                    <h5 className='mb-3 mt-4'>Current shooters</h5>
                    <div className="d-flex flex-wrap">
                        {selectedShooters.length > 0 ? (
                            selectedShooters.map((shooter) => (
                                <div key={shooter.eventShooterId} className="current-shooter-item">
                                    <h6>{shooter.shooterName || `${shooter.firstName} ${shooter.lastName}`}</h6>
                                    <button 
                                        className={`square-action red-action`}
                                        onClick={() => removeShooter(shooter)}
                                    >
                                        <Trash2 size={15} />
                                    </button>
                                </div>
                            ))
                        ) : (
                            <p>No shooters selected for this event.</p>
                        )}
                    </div>
                </div>

                <div className="modal-ctas mt-5">
                    <button type="button" className="dashboard-default-btn" onClick={closeModal}>
                        Cancel
                    </button>
                </div>

            </div>
        </div>
    );
};

export default EditShooters;
