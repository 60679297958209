import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ApiRepos } from '../contracts/ContractVars';

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await fetch(`${ApiRepos}account/verify`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                if (!response.ok) {
                    throw new Error(data.error || 'Authentication check failed.');
                }

                setIsAuthenticated(data.isAuthenticated);
            } catch (err) {
                console.error('Authentication check error:', err);
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/" state={{ from: location, message: 'You are not logged in. Please log in to access this page.' }} replace />;
    }

    return children;
};

export default ProtectedRoute;