import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import BenefitsSection from './components/BenefitsSection'
import './Clubs.css';
import LoginModal from '../../components/loginSection/LoginModal';
import { ApiRepos } from '../../contracts/ContractVars';

const libraries = ['marker'];

const mapContainerStyle = {
    width: '100%',
    height: '400px'
};

const Clubs = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [clubs, setClubs] = useState([]);
    const [location, setLocation] = useState('');
    const [clubName, setClubName] = useState('');
    const [center, setCenter] = useState({ lat: 54.5, lng: -4 });
    const [zoom, setZoom] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedClub, setSelectedClub] = useState(null);
    const mapRef = useRef(null);
    const markersRef = useRef([]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDu74EMNNqnvTVsAoz-375ia0BLH0A3HkU",
        libraries
    });

    const openLoginModal = () => setIsLoginModalOpen(true);
    const closeLoginModal = () => setIsLoginModalOpen(false);

    const fetchClubs = async () => {
        setIsLoading(true);
        try {
            const params = new URLSearchParams();
            if (location) params.append('location', location);
            if (clubName) params.append('clubName', clubName);

            const url = `${ApiRepos}clubs/advanced-search?${params.toString()}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setClubs(data);

            if (data.length > 0) {
                setCenter({ lat: data[0].latitude, lng: data[0].longitude });
                setZoom(11);
            }
        } catch (error) {
            console.error('Error fetching clubs:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        await fetchClubs();
    };

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const renderMarkers = useCallback(async () => {
        if (!mapRef.current || clubs.length === 0 || !window.google) return;

        // Clear existing markers
        markersRef.current.forEach(marker => marker.setMap(null));
        markersRef.current = [];

        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");

        clubs.forEach((club) => {
            const marker = new AdvancedMarkerElement({
                map: mapRef.current,
                position: { lat: club.latitude, lng: club.longitude },
                title: club.name,
            });

            marker.addListener("click", () => {
                setSelectedClub(club);
            });

            markersRef.current.push(marker);
        });
    }, [clubs]);

    useEffect(() => {
        if (isLoaded && mapRef.current) {
            renderMarkers();
        }
    }, [isLoaded, renderMarkers]);

    if (loadError) return "Error loading maps";
    if (!isLoaded) return "Loading Maps";

    return (
        <div className="clubs-page">
            <Header openLoginModal={openLoginModal} />
            <main>
                <section className="clubs-section" id="clubs">
                    <div className="container">
                        <div className="clubs-content">
                            <h1 className="section-headline-light">Find a Shooting Club</h1>
                            <h2 className="section-sub-headline-light">
                                Search for shooting clubs near you or by name.
                            </h2>
                            <form className="clubs-search-form" onSubmit={handleSearch}>
                                <div className="form-field">
                                    <input
                                        type="text"
                                        id="location"
                                        name="location"
                                        placeholder="Enter postcode or location"
                                        className="text-field"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        aria-label="Search by location"
                                    />
                                </div>
                                <div className="form-field">
                                    <input
                                        type="text"
                                        id="clubName"
                                        name="clubName"
                                        placeholder="Enter club name"
                                        className="text-field"
                                        value={clubName}
                                        onChange={(e) => setClubName(e.target.value)}
                                        aria-label="Search by club name"
                                    />
                                </div>
                                <div className="form-submit2">
                                    <button type="submit" className="default-btn" disabled={isLoading}>
                                        {isLoading ? 'Searching...' : 'Search'}
                                    </button>
                                </div>
                            </form>
                            <div className="clubs-map">
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    center={center}
                                    zoom={zoom}
                                    onLoad={onMapLoad}
                                    options={{ mapId: "DEMO_MAP_ID" }}
                                >
                                </GoogleMap>
                            </div>
                            {selectedClub && (
                                <div className="club-details">
                                    <h3>{selectedClub.name}</h3>
                                    <p>{selectedClub.address}</p>
                                    {selectedClub.distance && (
                                        <p>Distance: {selectedClub.distance.toFixed(1)} km</p>
                                    )}
                                    {selectedClub.phoneNumber && <p>Phone: {selectedClub.phoneNumber}</p>}
                                    {selectedClub.email && <p>Email: {selectedClub.email}</p>}
                                    {selectedClub.website && (
                                        <p>Website: <a href={selectedClub.website} target="_blank" rel="noopener noreferrer">{selectedClub.website}</a></p>
                                    )}
                                </div>
                            )}
                            <div className="clubs-list">
                                <h3 className="club-list-title">Club List</h3>
                                {clubs.length > 0 ? (
                                    <ul>
                                        {clubs.map((club) => (
                                            <li key={club.clubId} className="club-item" onClick={() => setSelectedClub(club)}>
                                                <strong>{club.name}</strong> - {club.address}
                                                {club.distance && (
                                                    <span className="club-distance"> ({club.distance.toFixed(1)} km)</span>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No clubs found. Try a different search term.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <BenefitsSection />
            </main>
            <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
            <Footer />
        </div>
    );
};

export default Clubs;