import React from 'react'
import './Footer.css'
import logo from '../../assets/images/logo.svg'
import { Link } from 'react-router-dom'
import x from '../../assets/icons/x.svg'
import facebook from '../../assets/icons/facebook.svg'
import instagram from '../../assets/icons/instagram.svg'

const Footer = () => {
  return (
    <footer className='footer'>
        <div className="container">
            <div className="footer-links">
                <Link to={'/'} className='footer-logo'>
                    <img src={logo} alt="ShootConnect" />
                </Link>
                
                <ul className='footer-social'>
                      <li><a href="https://www.facebook.com/profile.php?id=61566409050983" target="_facebook"><img src={facebook} alt="Facebook" /></a></li>
                      <li><a href="https://x.com/shootconnect" target="_x"><img src={x} alt="X" /></a></li>
                      <li><a href="https://www.instagram.com/shootconnect.app/"><img src={instagram} alt="Instagram" /></a></li>
                </ul>
            </div>
              <ul className='footer-navlinks'>
                  <li><Link to={'/terms'}>Terms & Conditions and Privacy Policy</Link></li>
              </ul>
            <div className="footer-copyright">
                &copy; 2023 <Link to={'/'}>ShootConnect</Link>. All rights reserved.
            </div>
        </div>
    </footer>
  )
}

export default Footer