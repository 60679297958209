import React, { useState, useEffect } from 'react';
import { ApiRepos } from '../../../../contracts/ContractVars';
import ClubSelector from './ClubSelector';

const EditEvent = ({ isOpen, closeModal, event, onEventUpdated }) => {
    const [eventData, setEventData] = useState({
        name: '',
        clubId: '',
        clubName: '',
        eventDate: '',
        eventTime: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isOpen && event) {
            setEventData({
                name: event.name || '',
                clubId: event.clubId || '',
                clubName: event.clubName || '',
                eventDate: event.eventDate ? new Date(event.eventDate).toISOString().split('T')[0] : '',
                eventTime: event.eventTime || '',
            });
        }
    }, [isOpen, event]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEventData(prev => ({ ...prev, [name]: value }));
    };

    const handleClubSelect = (club) => {
        setEventData(prev => ({
            ...prev,
            clubId: club.clubId,
            clubName: club.name
        }));
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        // Convert eventDate to dd/mm/yyyy for display consistency
        const formattedEventData = {
            ...eventData,
            eventDate: new Date(eventData.eventDate).toISOString().split('T')[0]
        };

        try {
            const response = await fetch(`${ApiRepos}events/${event.eventId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(formattedEventData)
            });
            if (!response.ok) throw new Error('Failed to update event');
            const updatedEvent = await response.json();
            onEventUpdated(updatedEvent);
            closeModal();
        } catch (error) {
            console.error('Error updating event:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="create-event-container active">
            <div className="create-event-overlay" onClick={closeModal}></div>
            <div className="create-event-box">
                <div className="modal-header">
                    <h2>Edit Event</h2>
                </div>
                <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Name</label>
                                <div className="dashboard-form-field">
                                    <input
                                        type="text"
                                        name="name"
                                        value={eventData.name}
                                        onChange={handleInputChange}
                                        placeholder='Event Name'
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Club</label>
                                <ClubSelector
                                    selectedClub={eventData.clubId ? {
                                        id: eventData.clubId,
                                        name: eventData.clubName
                                    } : null}
                                    onClubSelect={handleClubSelect}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Date</label>
                                <div className="dashboard-form-field">
                                    <input
                                        type="date"
                                        name="eventDate"
                                        value={eventData.eventDate}
                                        onChange={handleInputChange}
                                        className='create-event-input'
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className='dashboard-form-label'>Time</label>
                                <div className="dashboard-form-field">
                                    <input
                                        type="time"
                                        name="eventTime"
                                        value={eventData.eventTime}
                                        onChange={handleInputChange}
                                        className='create-event-input'
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-ctas">
                        <button type="button" className="dashboard-outline-btn me-2" onClick={closeModal}>
                            Cancel
                        </button>
                        <button type="submit" className="dashboard-default-btn" disabled={isSubmitting}>
                            {isSubmitting ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditEvent;
